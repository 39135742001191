import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, defaultIfEmpty, map, mergeMap, share, switchMap, tap } from "rxjs/operators";
import { BagGegevens } from "../model/dto/BagGegevens";
import { Resultaat } from "../model/dto/Resultaat";
import { SearchEvent } from "../model/dto/SearchEvent";
import { WozGegevens } from "../model/dto/WozGegevens";
import { ResultaatMapper } from "../model/mapper/ResultaatMapper";
import { BagService } from "./bag.service";
import { KadastraleKaartService } from "./kadastrale-kaart.service";
import { WozService } from "./woz.service";
import { AdresseerbaarObjectSuggestion } from "../model/dto/AdresseerbaarObjectSuggestion";
import { PrefixPipe } from "../shared/utils/prefix.pipe";
import { ArceringService } from "./arcering.service";
import { Feature } from "ol";
import { Geometry } from "ol/geom";

@Injectable({
    providedIn: "root",
})
export class ResultaatService {
    constructor(
        private bagService: BagService,
        private wozService: WozService,
        private prefixPipe: PrefixPipe,
        private kadastraleKaartService: KadastraleKaartService,
        private arceringService: ArceringService
    ) {}

    getResultaat(event: SearchEvent, zoomTo: boolean): Observable<Resultaat> {
        return this.fetchResultaat(event).pipe(
            tap((result) => this.arceerResult(result, zoomTo)),
            map((result) => ResultaatMapper.mapResult(result, event))
        );
    }

    getResultaatAndSuggestions(event: SearchEvent): Observable<[Resultaat, AdresseerbaarObjectSuggestion[]]> {
        const partialResult: Observable<[WozGegevens, BagGegevens]> = this.fetchResultaat(event).pipe(share());

        const suggestions: Observable<AdresseerbaarObjectSuggestion[]> = partialResult.pipe(
            switchMap(([result]) => {
                if (!result && (event.adresseerbaarobjectId || event.searchAdresseerbaarObjectId)) {
                    return this.wozService.searchByAdresseerbaarObjectIds([
                        event.adresseerbaarobjectId ? event.adresseerbaarobjectId : event.searchAdresseerbaarObjectId,
                    ]);
                } else if (!result && event.straat) {
                    return this.wozService.searchByStreet(event.straat);
                }
                return of([]);
            }),
            share()
        );

        const result: Observable<Resultaat> = forkJoin([partialResult, suggestions]).pipe(
            switchMap(([[woz, bag], suggestions]) => {
                if (!woz && suggestions.length === 1) {
                    return this.fetchResultaat({
                        wozobjectnummer: suggestions[0].id,
                    });
                } else if (suggestions.length === 0) {
                    return of([woz, bag]);
                }
                return of([null, null]);
            }),
            tap((result: [WozGegevens, BagGegevens]) => this.arceerResult(result, true)),
            map((result) => ResultaatMapper.mapResult(result, event))
        );

        return forkJoin([result, suggestions]);
    }

    private fetchResultaat(event: SearchEvent): Observable<[WozGegevens, BagGegevens]> {
        return this.fetchWozGegevens(event).pipe(
            mergeMap((wozGegevens) => forkJoin([of(wozGegevens), this.fetchBagGegevens(event, wozGegevens)]))
        );
    }

    private fetchBagGegevens(event: SearchEvent, wozGegevens: WozGegevens): Observable<BagGegevens> {
        if (wozGegevens?.wozObject?.adresseerbaarobjectid) {
            return this.getPandGegevens(wozGegevens.wozObject.adresseerbaarobjectid.toString(10));
        } else if (event.adresseerbaarobjectId) {
            return this.getPandGegevens(event.adresseerbaarobjectId);
        } else if (!wozGegevens && event.searchAdresseerbaarObjectId) {
            return this.getPandGegevens(event.searchAdresseerbaarObjectId);
        }
        return of(null);
    }

    private fetchWozGegevens(event: SearchEvent): Observable<WozGegevens> {
        if (event.wozobjectnummer) {
            return this.wozService.byWozobjectnummer(event.wozobjectnummer);
        } else if (event.nummeraanduidingId) {
            return this.wozService.byNummeraanduiding(event.nummeraanduidingId);
        }
        return of(null);
    }

    private arceerResult([wozGegevens, bagGegevens]: [WozGegevens, BagGegevens], zoomTo: boolean) {
        this.fetchAllFeatures(wozGegevens, bagGegevens).subscribe(([bagFeatures, vboFeatures, kadastraleFeatures]) => {
            const features = [
                ...(bagFeatures ? bagFeatures : []),
                ...(vboFeatures ? vboFeatures : []),
                ...(kadastraleFeatures ? kadastraleFeatures : []),
            ];
            this.bagService.zoomToArcering(features, bagGegevens, zoomTo);
            this.arceringService.setFeatures(bagFeatures, vboFeatures, kadastraleFeatures, wozGegevens?.wozObject);
        });
    }

    private fetchVboFeatures(wozGegevens: WozGegevens, bagGegevens: BagGegevens): Observable<BagGegevens[]> {
        const bagVbo: string = bagGegevens?.identificatie;
        const bagIds: number[] = wozGegevens?.wozObject?.verbondenAdresseerbareObjecten
            ? wozGegevens?.wozObject?.verbondenAdresseerbareObjecten
            : [];
        const vboIds = [...new Set([...bagIds.map((id) => this.prefixPipe.transform(id.toString(10), 16, "0"))])]
            .filter((id) => !!id)
            .filter((id) => !bagVbo || id !== bagVbo);

        return vboIds.length > 0 ? this.getAllPandGegevens(vboIds) : of([]);
    }

    private fetchPandFeatures(
        vboGegevens: BagGegevens[],
        wozGegevens: WozGegevens,
        bagGegevens: BagGegevens
    ): Observable<Feature<Geometry>[]> {
        const plaatsGegevens: Feature<Geometry>[] = [...vboGegevens, bagGegevens]
            .filter((vbo) => !!vbo)
            .filter(
                (vbo) =>
                    vbo?.identificatie &&
                    (vbo.identificatie.slice(4).startsWith("02") || vbo.identificatie.slice(4).startsWith("03"))
            )
            .map((vbo) => vbo.geometry)
            .filter((geo) => !!geo)
            .reduce((acc, geos) => [...acc, ...geos], []);

        const pandIds = [
            ...new Set(
                [
                    ...vboGegevens.map((vbo) => vbo.pandidentificatie),
                    bagGegevens?.pandidentificatie,
                    ...(wozGegevens?.panden
                        ? wozGegevens.panden.map((pand) => pand.bagpandidentificatie.toString(10))
                        : []),
                    bagGegevens?.pandidentificatie,
                ].filter((id) => !!id)
            ),
        ];
        return pandIds.length > 0
            ? this.bagService.pandByPandIdFeatures(pandIds)
            : of([]).pipe(map((panden) => [...panden, ...plaatsGegevens]));
    }

    private fetchAllFeatures(
        wozGegevens: WozGegevens,
        bagGegevens: BagGegevens
    ): Observable<[Feature<Geometry>[], Feature<Geometry>[], Feature<Geometry>[]]> {
        if (wozGegevens == null && bagGegevens == null) {
            return of([[], [], []]);
        }

        return this.fetchVboFeatures(wozGegevens, bagGegevens).pipe(
            defaultIfEmpty([]),
            switchMap((vboGegevens) =>
                this.fetchPandFeatures(vboGegevens, wozGegevens, bagGegevens).pipe(
                    defaultIfEmpty([]),
                    switchMap((pandGegevens) =>
                        (wozGegevens?.kadastraleObjecten
                            ? this.kadastraleKaartService.fetchKadastraalPerceelFeatures(
                                  wozGegevens?.kadastraleObjecten
                              )
                            : of([])
                        ).pipe(
                            defaultIfEmpty([]),
                            map((kozGegevens) => [
                                pandGegevens,
                                [...new Set(bagGegevens?.center_geo ? bagGegevens.center_geo : null)].filter(
                                    (vbo) => !!vbo
                                ),
                                kozGegevens,
                            ])
                        )
                    )
                )
            ),
            map(([pandGegevens, vboGegevens, kozGegevens]) => [pandGegevens, vboGegevens, kozGegevens])
        );
    }

    private getPandGegevens(adresseerbaarobjectId: string): Observable<BagGegevens> {
        return this.getAllPandGegevens([adresseerbaarobjectId]).pipe(
            map((gegevens) => (gegevens ? gegevens[0] : null))
        );
    }

    private getAllPandGegevens(adresseerbaarObjectIds: string[]): Observable<BagGegevens[]> {
        return this.bagService.getAllPandGegevens(adresseerbaarObjectIds).pipe(
            catchError((err) => {
                console.log(err);
                return of([]);
            })
        );
    }
}
