import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

export interface OpenedBlocks {
    filters?: boolean;
    filterResult?: boolean;
    suggestions?: boolean;
    result?: boolean;
}

@Injectable({
    providedIn: "root",
})
export class UiService {
    selectedBackground$ = new BehaviorSubject<string>("kaart");
    sidebarOpen$ = new Subject<boolean>();
    openedBlocks$ = new BehaviorSubject<OpenedBlocks>({});
    private openedBlocks: OpenedBlocks = {};

    setBackground(value: string): void {
        this.selectedBackground$.next(value.toLowerCase());
    }

    toggleBlock(block: "result" | "suggestions" | "filterResult" | "filters", open: boolean): void {
        this.openedBlocks[block] = open;
        this.openedBlocks$.next(this.openedBlocks);
        this.sidebarOpen$.next(
            Object.keys(this.openedBlocks).filter((key) => this.openedBlocks[key] === true).length > 0
        );
    }
}
