import { Component, Input, OnInit } from "@angular/core";
import { Observable, timer } from "rxjs";
import { buffer, filter, map, repeat } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Adres } from "../../model/dto/Adres";
import { BagGegevens } from "../../model/dto/BagGegevens";
import { Resultaat } from "../../model/dto/Resultaat";
import { Waarde } from "../../model/dto/Waarde";
import { ArceringService } from "../../service/arcering.service";
import { PrefixPipe } from "../../shared/utils/prefix.pipe";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-result",
    templateUrl: "./resultaat.component.html",
    styleUrls: ["./resultaat.component.scss"],
})
export class ResultaatComponent implements OnInit {
    adresseerbaarobjectid: string;
    hasNoLocatie: Observable<boolean>;
    nummeraanduidingid: string;
    showAllWaarden = false;
    faExternalLink = faExternalLinkAlt;

    get resultaat(): Resultaat {
        return this._resultaat;
    }

    @Input()
    set resultaat(value: Resultaat) {
        this._resultaat = value;
        if (value?.nummeraanduidingid) {
            this.nummeraanduidingid = this.prefixPipe.transform(value.nummeraanduidingid, 16, "0");
        }
        if (value?.adresseerbaarobjectid) {
            this.adresseerbaarobjectid = this.prefixPipe.transform(value.adresseerbaarobjectid, 16, "0");
        }
    }

    private _resultaat: Resultaat;

    constructor(private arceringService: ArceringService, private prefixPipe: PrefixPipe) {}

    ngOnInit(): void {
        this.hasNoLocatie = this.arceringService.arceringen.pipe(
            buffer(timer(environment.noLocationTimeout)),
            filter((values) => values.length > 0),
            map((values) => {
                const value = values[values.length - 1];
                return [].concat(value.vboFeatures, value.kadastraleFeatures, value.bagFeatures).length === 0;
            }),
            repeat(environment.noLocationRepeat)
        );
    }

    get hasAdress(): boolean {
        const adres: Adres = this.resultaat.adresGegevens;
        return !!(
            adres?.straatnaam ||
            adres?.woonplaatsnaam ||
            adres?.huisnummer ||
            adres?.huisnummertoevoeging ||
            adres?.huisletter ||
            adres?.locatieomschrijving ||
            adres?.postcode
        );
    }

    get hasBagKenmerken(): boolean {
        const bag: BagGegevens = this.resultaat?.pandGegevens;
        return !!(bag?.bouwjaar || bag?.gebruiksdoel || bag?.oppervlakte);
    }

    get waardenPreview(): Waarde[] {
        return this.resultaat?.wozGegevens?.wozWaarden?.slice(0, environment.resultWaardenLimit);
    }

    get waarden(): Waarde[] {
        return this.resultaat?.wozGegevens?.wozWaarden;
    }

    get maxWaarden(): number {
        return environment.resultWaardenLimit;
    }
}
