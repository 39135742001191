import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "app-terms-info",
    templateUrl: "./terms-info.component.html",
    styleUrls: ["./terms-info.component.scss"],
})
export class TermsInfoComponent {
    @Output()
    close: EventEmitter<void> = new EventEmitter();

    onClose(): void {
        this.close.emit();
    }
}
