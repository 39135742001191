import { Component, Input } from "@angular/core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-sidebar-loader",
    templateUrl: "./sidebar-loader.component.html",
    styleUrls: ["./sidebar-loader.component.scss"],
})
export class SidebarLoaderComponent {
    @Input()
    title: string;

    loadingIcon = faSpinner;
}
