<header>
    <div class="logo" [ngClass]="{ 'logo--hidden': sidebarOpen | async }">
        <img
            class="logo__image"
            src="./assets/logo/logotype-government-of-the-netherlands-nl.png"
            alt="Rijksoverheid logo"
        />
    </div>
    <nav class="navbar">
        <a href="#" title="Naar home" (click)="$event.preventDefault(); reload()">
            <img src="./assets/icons/home.svg" role="presentation" alt="Naar home" />
        </a>
        <a href="#" class="inline-link" data-id="totstandkoming-link" [routerLink]="['totstandkoming']"
            >Totstandkoming WOZ-waarde</a
        >
        <a href="#" class="inline-link" data-id="veelgesteldevragen-link" [routerLink]="['veelgesteldevragen']"
            >Veelgestelde vragen</a
        >
        <a href="#" class="inline-link" data-id="werkingwozwaardeloket-link" [routerLink]="['werkingwozwaardeloket']"
            >Werking</a
        >
        <a href="#" class="inline-link" data-id="uitleggegevens-link" [routerLink]="['uitleggegevens']"
            >Uitleg gegevens</a
        >
        <label
            id="hamburger-label"
            for="hamburger"
            class="btn btn--transparent btn--square btn--hamburger hamburger__toggle"
            aria-label="Menu"
        >
            <span class="icon icon--hamburger"></span>
        </label>
        <button
            href="#"
            [ngClass]="{ hamburger_checked: hamburgerChecked }"
            (click)="hamburgerChecked = !hamburgerChecked"
            [attr.aria-expanded]="hamburgerChecked"
            class="hamburger__input"
            id="hamburger"
            aria-label="menu"
        ></button>

        <div class="hamburger__menu">
            <app-nav [hamburger]="true"></app-nav>
        </div>
    </nav>
</header>
