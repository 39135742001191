import { Injectable } from "@angular/core";
import { CrsConfig } from "@kadaster/generieke-geo-componenten-map";
import { Extent } from "ol/extent";

@Injectable({
    providedIn: "root",
})
export class CrsConfigService {
    public static MAX_ZOOMLEVEL = 25;
    rdNewCrsConfig: CrsConfig;

    // Copied from ggc, service is not exposed
    getRdNewCrsConfig(): CrsConfig {
        if (this.rdNewCrsConfig === undefined) {
            const epgs28992 = "EPSG:28992";
            const extent: Extent = [-285401.92, 22598.08, 595401.92, 903401.92];
            const resolutions = [
                3440.64, 1720.32, 860.16, 430.08, 215.04, 107.52, 53.76, 26.88, 13.44, 6.72, 3.36, 1.68, 0.84, 0.42,
                0.21, 0.105, 0.0525, 0.02625, 0.013125, 0.0065625, 0.00328125, 0.001640625, 0.000820313, 0.000410156,
                0.000205078, 0.000102539,
            ];
            const matrixSizes = [
                1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768, 65536, 131072, 262144,
                524288, 1048576, 2097152, 4194304, 8388608, 16777216, 33554432,
            ];
            const matrixIds = [];
            for (let i = 0; i < resolutions.length; ++i) {
                matrixIds[i] = epgs28992 + ":" + i;
            }

            this.rdNewCrsConfig = {
                projectionCode: epgs28992,
                extent,
                resolutions,
                matrixSet: epgs28992,
                matrixIds,
                matrixSizes,
                units: "m",
            };
        }
        return this.rdNewCrsConfig;
    }
}
