import { Component } from "@angular/core";
import { TermsService } from "../../service/terms.service";

@Component({
    selector: "app-voorscherm-modal",
    templateUrl: "./voorscherm-modal.component.html",
})
export class VoorschermModalComponent {
    modalState: "disclaimer" | "voorwaarden" = "disclaimer";

    open = true;

    constructor(private termsService: TermsService) {}

    onClose(): void {
        if (this.modalState === "voorwaarden") {
            this.modalState = "disclaimer";
        } else {
            this.termsService.acceptTerms();
            this.open = false;
        }
    }

    onTerms(): void {
        this.modalState = "voorwaarden";
    }
}
