<app-modal (close)="onClose()" title="Toegankelijkheidsverklaring">

    <p>Deze toegankelijkheidsverklaring is opgesteld op 05-09-2022.</p>
    <div>
        Het Kadaster wil zo snel mogelijk volledig voldoen aan de wet ‘Tijdelijk besluit digitale
        toegankelijkheid’.
    </div>
    <div>
        Voldoen aan deze standaard maakt onze website onder andere gebruiksvriendelijker en beter toegankelijk voor
        verschillende doelgroepen.
    </div>
    <div>
        Het Kadaster moet voldoen aan de Web Content Accessibility Guidelines (WCAG), versie 2.1, niveau AA.
    </div>
    <div>
        In deze toegankelijkheidsverklaring lichten wij toe op welke punten wij nog niet voldoen en op welke termijn wij
        verwachten de knelpunten op te lossen.
    </div>

    <p>Deze verklaring geldt voor de website(s):</p>

    <p><a href="https://www.wozwaardeloket.nl">https://www.wozwaardeloket.nl</a></p>
    <p><a href="https://www.woz-waardeloket.nl">https://www.woz-waardeloket.nl</a></p>

    <a href="https://www.toegankelijkheidsverklaring.nl/register/2678" target="_blank">
        <img
            src="https://www.toegankelijkheidsverklaring.nl/files/verklaring/label/b88225567d46c4dc0b8459c1dc48157f.2678.svg"
            alt="Status toegankelijkheidslabel van WOZ-waardeloket. Volg de link voor de volledige toegankelijkheidsverklaring."
            width="692"
            height="251"
        />
    </a>
</app-modal>
