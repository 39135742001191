import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AdresseerbaarObjectSuggestion } from "../../model/dto/AdresseerbaarObjectSuggestion";
import { SearchEvent } from "../../model/dto/SearchEvent";

@Component({
    selector: "app-result-nav",
    templateUrl: "./resultaat-nav.component.html",
    styleUrls: ["./resultaat-nav.component.scss"],
})
export class ResultaatNavComponent {
    private _suggestions: AdresseerbaarObjectSuggestion[];

    @Input()
    set suggestions(value: AdresseerbaarObjectSuggestion[]) {
        this._suggestions = value;
    }

    get suggestions(): AdresseerbaarObjectSuggestion[] {
        return this._suggestions;
    }

    @Input()
    indexSuggestion: number;

    @Output()
    lookup: EventEmitter<SearchEvent> = new EventEmitter();

    @Output()
    indexSuggestionChange: EventEmitter<number> = new EventEmitter();

    previousSuggestion(): void {
        const index = this.indexSuggestion - 1;
        this.selectSuggestion(index > 0 ? index - 1 : this.suggestions.length - 1);
    }

    nextSuggestion(): void {
        const index = this.indexSuggestion - 1;
        this.selectSuggestion(index >= this.suggestions.length - 1 ? 0 : index + 1);
    }

    selectSuggestion(index: number): void {
        if (this.suggestions[index] !== undefined) {
            this.lookup.emit({
                wozobjectnummer: this.suggestions[index].id,
                adresseerbaarobjectId: this.suggestions[index].adresseerbaarObjectId?.toString(),
            });
            this.indexSuggestionChange.emit(index + 1);
        }
    }
}
