import { Adres } from "../dto/Adres";
import { BagGegevens } from "../dto/BagGegevens";
import { WozObject } from "../dto/WozObject";
import { VerblijfsObject } from "../dto/VerblijfsObject";

export class AdresMapper {
    static fromPandGegevens(pandGegevens: BagGegevens): Adres {
        const adres = {};
        const map = new Map<string, string>([
            ["huisletter", "huisletter"],
            ["huisnummer", "huisnummer"],
            ["huisnummertoevoeging", "toevoeging"],
            ["postcode", "postcode"],
            ["straatnaam", "openbare_ruimte"],
            ["woonplaatsnaam", "woonplaats"],
        ]);

        map.forEach((prop, key) => {
            if (pandGegevens[prop]) {
                adres[key] = pandGegevens[prop].toString();
            }
        });
        return <Adres>adres;
    }

    static fromWozObject(wozObject: WozObject): Adres {
        const adres = {};
        const map = new Map<string, string>([
            ["huisletter", "huisletter"],
            ["huisnummer", "huisnummer"],
            ["huisnummertoevoeging", "huisnummertoevoeging"],
            ["postcode", "postcode"],
            ["straatnaam", "straatnaam"],
            ["locatieomschrijving", "locatieomschrijving"],
            ["woonplaatsnaam", "woonplaatsnaam"],
        ]);

        map.forEach((prop, key) => {
            if (wozObject[prop]) {
                adres[key] = wozObject[prop].toString();
            }
        });
        return <Adres>adres;
    }

    static fromBagResult(bag: BagGegevens): Adres {
        return {
            huisnummer: bag?.huisnummer,
            huisletter: bag?.huisletter,
            huisnummertoevoeging: bag?.toevoeging,
            postcode: bag?.postcode,
            straatnaam: bag?.openbare_ruimte,
            woonplaatsnaam: bag?.woonplaats,
        };
    }
    static fromVerblijfsObject(bag: VerblijfsObject): Adres {
        return {
            huisnummer: bag?.huisnummer,
            huisletter: bag?.huisletter,
            huisnummertoevoeging: bag?.toevoeging,
            postcode: bag?.postcode,
            straatnaam: bag?.openbare_ruimte,
            woonplaatsnaam: bag?.woonplaats,
        };
    }
}
