<ng-container *ngIf="$termsAccepted | async">
    <div cdkOverlayOrigin #origin="cdkOverlayOrigin"></div>

    <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="overlay"
        cdkConnectedOverlayPanelClass="modal"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="origin"
        [cdkConnectedOverlayOpen]="modalState.werking"
        (backdropClick)="onClose()"
        (detach)="onClose()"
    >
    </ng-template>

    <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="overlay"
        cdkConnectedOverlayPanelClass="modal"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="origin"
        [cdkConnectedOverlayOpen]="modalState.totstandkoming"
        (backdropClick)="onClose()"
        (detach)="onClose()"
    >
        <app-about-info (close)="onClose()"></app-about-info>
    </ng-template>

    <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="overlay"
        cdkConnectedOverlayPanelClass="modal"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="origin"
        [cdkConnectedOverlayOpen]="modalState.gebruiksvoorwaarden"
        (backdropClick)="onClose()"
        (detach)="onClose()"
    >
        <app-terms-info (close)="onClose()"></app-terms-info>
    </ng-template>

    <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="overlay"
        cdkConnectedOverlayPanelClass="modal"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="origin"
        [cdkConnectedOverlayOpen]="modalState.veelgesteldevragen"
        (backdropClick)="onClose()"
        (detach)="onClose()"
    >
        <app-veelgestelde-vragen (close)="onClose()"></app-veelgestelde-vragen>
    </ng-template>

    <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="overlay"
        cdkConnectedOverlayPanelClass="modal"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="origin"
        [cdkConnectedOverlayOpen]="modalState.werkingwozwaardeloket"
        (backdropClick)="onClose()"
        (detach)="onClose()"
    >
        <app-werking-wozwaardeloket (close)="onClose()"></app-werking-wozwaardeloket>
    </ng-template>
    <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="overlay"
        cdkConnectedOverlayPanelClass="modal"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="origin"
        [cdkConnectedOverlayOpen]="modalState.uitleggegevens"
        (backdropClick)="onClose()"
        (detach)="onClose()"
    >
        <app-uitleg-gegevens (close)="onClose()"></app-uitleg-gegevens>
    </ng-template>
    <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="overlay"
        cdkConnectedOverlayPanelClass="modal"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOrigin]="origin"
        [cdkConnectedOverlayOpen]="modalState.toegangkelijkheidsverklaring"
        (backdropClick)="onClose()"
        (detach)="onClose()"
    >
        <app-toegankelijkheids-verklaring (close)="onClose()"></app-toegankelijkheids-verklaring>
    </ng-template>
</ng-container>
