<p>
    <fa-icon
        [icon]="loadingIcon"
        spin="true"
        title="Gegevens worden geladen"
        aria-label="Gegevens worden geladen"
        class="fa-2xl"
        *ngIf="isPrinting && isZichtbaar()"
    ></fa-icon>
    <button
        class="btn btn--primary"
        id="button-print"
        [disabled]="isPrinting"
        *ngIf="!isPrinting && isZichtbaar()"
        (click)="print()"
    >
        PDF genereren
    </button>
</p>
