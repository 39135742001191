import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-error",
    templateUrl: "./error.component.html",
})
export class ErrorComponent {
    @Input() error: string;
    @Input() title = "Melding";

    @Output()
    close: EventEmitter<void> = new EventEmitter();

    onClose(): void {
        this.close.emit();
    }
}
