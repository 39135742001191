import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { MapComponentEvent, WmsLayerOptions } from "@kadaster/generieke-geo-componenten-map";
import { WmsLayerComponent } from "@kadaster/generieke-geo-componenten-map/lib/layer/wms-layer/wms-layer.component";
import { concat, Subscription } from "rxjs";
import { bufferCount, filter, map, mergeMap } from "rxjs/operators";
import { Dataset, Layer } from "@kadaster/generieke-geo-componenten-dataset-tree";

@Component({
    selector: "app-wms-dataset",
    templateUrl: "./wms-dataset.component.html",
    styleUrls: ["./wms-dataset.component.scss"],
})
export class WmsDatasetComponent implements AfterViewInit, OnDestroy {
    @Input()
    datasets: Dataset[];

    @Input()
    layer: Layer;

    @Input()
    mapName: string;

    @Output()
    events: EventEmitter<MapComponentEvent[]> = new EventEmitter();

    @ViewChildren("wmslayer")
    wmsLayer: QueryList<WmsLayerComponent>;

    readonly nonTiledLayers: string[] = ["Perceel"];

    private layerSubscription: Subscription;
    private wmsSubscription: Subscription;

    ngAfterViewInit(): void {
        this.setSubscription();
        this.wmsSubscription = this.wmsLayer.changes.subscribe(() => {
            this.destroySubscriptions();
            this.setSubscription();
        });
    }

    getWmsOptions(url: string, layer: Layer): WmsLayerOptions {
        return {
            url: url,
            layers: layer.technicalName,
            minResolution: layer.minResolution,
            maxResolution: layer.maxResolution,
            zIndex: layer.zIndex,
            getFeatureInfoOnSingleclick: layer.getFeatureInfoOnSingleclick,
            attributions: layer.attributions,
            mapName: this.mapName,
            layerName: layer.name,
            tiled: !this.nonTiledLayers.includes(layer.technicalName),
        };
    }

    ngOnDestroy(): void {
        this.destroySubscriptions();
        this.wmsSubscription?.unsubscribe();
    }

    private setSubscription() {
        this.destroySubscriptions();
        this.layerSubscription = concat(this.wmsLayer.map((layer) => layer.events.asObservable()))
            .pipe(
                mergeMap((eventSource) => eventSource),
                filter((event) => event.type === "WmsFeatureInfo"),
                map((event) => (event.value.length > 0 ? event : null)),
                bufferCount(this.wmsLayer.toArray().length)
            )
            .subscribe((events) => {
                this.events.emit([
                    events.find((event) => event && event.layerName === "Bag panden"),
                    events.find((event) => event && event.layerName === "Bag ligplaatsen"),
                    events.find((event) => event && event.layerName === "Bag standplaatsen"),
                    events.find((event) => event && event.layerName === "Perceel"),
                ]);
            });
    }

    private destroySubscriptions() {
        if (this.layerSubscription) {
            this.layerSubscription.unsubscribe();
        }
    }
}
