<app-modal (close)="onClose()" title="Gebruiksvoorwaarden">
    <p>Dit loket biedt de mogelijkheid om de WOZ-waarde van woningen individueel te raadplegen.</p>
    <p>
        Het beschikbaar stellen van WOZ-waarden is bedoeld om meer transparantie te bieden over de onderlinge
        verschillen tussen WOZ-waarden van woningen. De eigenaar van een woning of ander object kan hiermee beoordelen
        of de WOZ-waarde correct is.
    </p>
    <p>Volgens artikel 40b van de Wet WOZ kan de WOZ-waarde van woningen worden opgevraagd.</p>
    <p>
        Het massaal of geautomatiseerd onttrekken van gegevens aan het WOZ-Waardeloket en/of het verstrekken aan derden
        is niet toegestaan. Daarom is er een maximum gesteld aan het aantal woningen dat men binnen een bepaalde
        tijdsperiode mag opvragen.
    </p>
</app-modal>
