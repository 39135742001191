<div cdkOverlayOrigin #origin="cdkOverlayOrigin"></div>

<ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayBackdropClass="overlay"
    cdkConnectedOverlayPanelClass="modal"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayOpen]="open"
    (backdropClick)="onClose()"
    (detach)="onClose()"
>
    <app-voorscherm *ngIf="modalState === 'disclaimer'" (close)="onClose()" (terms)="onTerms()"></app-voorscherm>
    <app-terms-info *ngIf="modalState === 'voorwaarden'" (close)="onClose()"></app-terms-info>
</ng-template>
