import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class TermsService {
    get $termsAccepted(): Observable<boolean> {
        return this._termsAccepted.asObservable();
    }

    private _termsAccepted: Subject<boolean> = new BehaviorSubject(false);

    acceptTerms(): void {
        this._termsAccepted.next(true);
    }
}
