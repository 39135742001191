import { Injectable } from "@angular/core";
import { MapService } from "@kadaster/generieke-geo-componenten-map";
import { Coordinate } from "ol/coordinate";
import { Layer } from "../../model/print/Layer";
import { MapfishMap } from "../../model/print/MapFishMap";

@Injectable({
    providedIn: "root",
})
export class MapPrintService {
    constructor(private mapService: MapService) {}

    createMap(scale: number, coord: Coordinate, mapName?: string, layers?: Layer[]): MapfishMap {
        const dpi = 180;
        const projection = this.mapService.getMap(mapName).getView().getProjection().getCode();
        // Coordinate is an array of numbers, get first 2 numbers as center for the MapFishMap
        const center: [number, number] = [coord[0], coord[1]];
        return { center, dpi, scale, layers, projection };
    }
}
