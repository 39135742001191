import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-werking-wozwaardeloket",
    templateUrl: "./werking-wozwaardeloket.component.html",
    styleUrls: ["./werking-wozwaardeloket.component.scss"],
})
export class WerkingWozwaardeloketComponent implements AfterViewInit {
    faExternalLink = faExternalLinkAlt;

    @ViewChild("werkingZoeken")
    werkingZoeken: ElementRef;

    @ViewChild("werkingKlikken")
    werkingKlikken: ElementRef;

    @ViewChild("werkingFilteren")
    werkingFilteren: ElementRef;

    @ViewChild("werkingKaartlagen")
    werkingKaartlagen: ElementRef;

    @ViewChild("werkingBediening")
    werkingBediening: ElementRef;

    private headers = {};

    @Output()
    close: EventEmitter<void> = new EventEmitter();

    ngAfterViewInit(): void {
        this.headers = {
            zoeken: this.werkingZoeken,
            klikken: this.werkingKlikken,
            filteren: this.werkingFilteren,
            kaartlagen: this.werkingKaartlagen,
            bediening: this.werkingBediening,
        };
    }

    onClose(): void {
        this.close.emit();
    }

    navTo(childRef: string): void {
        const header: ElementRef = this.headers[childRef];
        if (header) {
            header.nativeElement.focus();
            header.nativeElement.scrollIntoView();
        }
    }
}
