<app-modal (close)="onClose()" title="Hoe werkt het WOZ-waardeloket?">
    <p>
        U leest hier hoe u de WOZ-waarde van een of meerdere woningen opzoekt en bekijkt in het WOZ-waardeloket. U kunt
        dit helemaal doorlezen, of een onderwerp uit de lijst kiezen:
    </p>
    <ul>
        <li>
            <button type="button" class="btn btn-link" id="werking-nav-zoeken" (click)="navTo('zoeken')">
                <span class="btn-text">Adres of adressen zoeken</span>
            </button>
        </li>

        <li>
            <button type="button" class="btn btn-link" id="werking-nav-klikken" (click)="navTo('klikken')">
                <span class="btn-text">Gegevens vinden door te klikken op de kaart</span>
            </button>
        </li>
        <li>
            <button type="button" class="btn btn-link" id="werking-nav-filteren" (click)="navTo('filteren')">
                <span class="btn-text">Woningen in het kaartbeeld filteren</span>
            </button>
        </li>
        <li>
            <button type="button" class="btn btn-link" id="werking-nav-kaartlagen" (click)="navTo('kaartlagen')">
                <span class="btn-text">Kaartlagen gebruiken</span>
            </button>
        </li>
        <li>
            <button type="button" class="btn btn-link" id="werking-nav-bediening" (click)="navTo('bediening')">
                <span class="btn-text">Bediening</span>
            </button>
        </li>
    </ul>
    <h3 #werkingZoeken id="werking-zoeken">Adres of adressen zoeken</h3>
    <p>
        In het zoekveld ‘Zoeken op postcode, adres of plaats’ in het scherm geeft u aan van welk(e) adres(sen) u de
        WOZ-waarde wilt bekijken. Dat kan door
    </p>
    <ul>
        <li>een volledig adres in te voeren of</li>
        <li>een postcode plus huisnummer in te voeren</li>
        <li>een deel van het adres in te voeren, bijvoorbeeld de straatnaam of een postcode.</li>
    </ul>
    <p>
        Na het invullen van 3 tekens geeft de zoekcomponent automatisch enkele suggesties. Het gewenste adres kunt u
        kiezen door op de suggestie te klikken. Staat het door u gezochte adres er nog niet bij, dan worden bij elk
        extra teken dat u invult, de suggesties verder verfijnd.
    </p>
    <p>
        Wanneer u een adres heeft geselecteerd, wordt de woning of een ander gebouw dat bij dat adres hoort, op de kaart
        gemarkeerd door een geel, blauw omlijnd vlak met hierin een blauw omlijnde stip. Het kan zijn dat er meerdere
        gebouwen gemarkeerd worden, deze gebouwen horen dan ook bij dit WOZ-object.
    </p>

    <h3 #werkingKlikken id="werking-klikken">Gegevens vinden door te klikken op de kaart</h3>
    <p>
        Als u op de kaart klikt op een woning of een ander gebouw, wordt deze gemarkeerd door een geel, blauw omlijnd
        vlak. Naast het klikken op een gebouw is het ook mogelijk om te klikken op een kadastraal perceel. Het
        aanklikken van een woning op de kaart is alleen mogelijk wanneer u voldoende bent ingezoomd (zie hieronder, bij
        onderwerp 'bediening").
    </p>
    <p>
        Naast de kaart staan de gegevens over de geselecteerde woning. Zijn er meer woningen geselecteerd, bijvoorbeeld
        omdat het aangewezen gebouw een appartementencomplex is of een kadastraal perceel met meerdere woningen, dan
        ziet u een lijst met resultaten met daarin de adressen. Klikt u daarna op het gewenste adres, dan ziet u de
        gegevens van deze woning, waaronder de WOZ-waarde (als deze beschikbaar is).
    </p>
    <p>
        Wilt u de gegevens van een woning ernaast, in de straat of dezelfde buurt bekijken? Klik dan de gewenste woning
        aan in de kaart of kies deze in de resultatenlijst. U ziet dan de gegevens van deze woning.
    </p>

    <h3 #werkingFilteren id="werking-filteren">Woningen in het kaartbeeld filteren</h3>
    <p>
        U kunt ook filteren in de woningen die u ziet op de kaart. De filters vindt u onder het veld waar u een adres
        kunt invullen. Filteren op de kaart is alleen beschikbaar als u ver genoeg bent ingezoomd (zie hieronder, bij
        onderwerp 'bediening").
    </p>
    <p>
        U kunt filteren op bouwjaar, oppervlakte van de woning en de WOZ-waarde. Dat doet u door voor een genoemd
        onderwerp een minimum en/of een maximum op te geven. Bijvoorbeeld: een WOZ-waarde tussen €325.000 en €350.000. U
        kunt ook een combinatie van filters gebruiken. Daarna klikt u op ‘Toepassen’. U ziet dan alle woningen die aan
        de voorwaarden voldoen. Deze worden lichtblauw en blauw omlijnd gemarkeerd op de kaart die u op dat moment op uw
        scherm staat. Tevens toont het WOZ-waardeloket een lijst met de bijbehorende adressen. Als u één van de
        gemarkeerde woningen of adressen aanklikt, ziet u de WOZ-waarde en andere gegevens van die woning.
    </p>

    <h3 #werkingKaartlagen id="werking-kaartlagen">Kaartlagen gebruiken</h3>
    <p>Met de knop Weergave, linksonder in de kaart, kiest u verschillende kaartlagen. U kunt kiezen voor</p>
    <ul>
        <li>een achtergrondkaart of</li>
        <li>een luchtfoto.</li>
    </ul>
    <p>Door vinkjes aan of uit te zetten kunt u kiezen voor het wel of niet tonen van</p>
    <ul>
        <li>
            panden, standplaatsen en ligplaatsen zoals geregistreerd in de Basisregistratie Adressen en Gebouwen en/of
        </li>
        <li>kadastrale percelen.</li>
    </ul>

    <h3 #werkingBediening id="bediening">Bediening</h3>
    <p>
        In- en uitzoomen kan met de knoppen Zoom in ("+") en Zoom uit ("--") rechtsonder in de kaart. Alleen wanneer u
        voldoende bent ingezoomd (de twee meest gedetailleerde niveaus) kunt u ook woningen selecteren in de kaart.
    </p>
    <p>
        De pop-up velden "Filteren op de kaart", "Resultaten" en "Geselecteerde resultaat" kunt u open- en dichtklappen
        met de knoppen Pijltje omhoog en Pijltje omlaag linksboven in deze pop-up velden.
    </p>
    <p>
        De pop-up velden "Resultaten" en "Geselecteerde resultaat" kunt u volledig sluiten met de knop met het kruisje
        rechtsboven in deze pop-up velden. Zodra u weer een woning selecteert, opent het pop-up veld "Resultaten" weer
        automatisch.
    </p>
</app-modal>
