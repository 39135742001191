<div>
    <ggc-search
        [initialSearchterm]="term"
        [numberOfSuggestions]="numberOfSuggestions"
        [classSearchButton]="'icon icon--search'"
        [classClearButton]="'icon icon--remove'"
        [minQueryLength]="3"
        [placeholderTextAndAriaLabel]="'Zoeken op postcode, adres of plaats'"
        (events)="searchEvent($event)"
    >
    </ggc-search>
</div>
