<section class="sidebar-block" [class.sidebar-block--open]="isOpen">
    <div class="sidebar-block__header">
        <button
            class="btn btn--transparent btn--square"
            (click)="onToggle()"
            [id]="'button-toggle-' + identifier"
            [attr.aria-expanded]="isExpanded"
            aria-labelledby="filterenopdekaart"
            title="{{ toggleTitle + ' ' + (isOpen ? 'verbergen' : 'tonen') }}"
        >
            <span class="icon icon--chevron icon--black" [class.icon--chevron-down]="isOpen"></span>
        </button>
        <h2 id="filterenopdekaart">{{ title }}</h2>
        <button
            *ngIf="closeable"
            class="btn btn--transparent btn--square"
            (click)="onClose()"
            title="{{ toggleTitle + ' sluiten' }}"
        >
            <span class="icon icon--close icon--black" [id]="'button-close-' + identifier"></span>
        </button>
    </div>
    <div class="sidebar-block__content">
        <ng-content></ng-content>
    </div>
</section>
