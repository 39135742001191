import { Component, Input } from "@angular/core";
import { Coordinate } from "ol/coordinate";
import { getCenter } from "ol/extent";
import { combineLatest, of } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Arcering } from "../../model/dto/Arcering";
import { Resultaat } from "../../model/dto/Resultaat";
import { PrintMapper } from "../../model/mapper/PrintMapper";
import { Attributes } from "../../model/print/Attributes";
import { Layer } from "../../model/print/Layer";
import { MapFishPrintRequest } from "../../model/print/MapFishPrintRequest";
import { PrintResponse } from "../../model/print/PrintResponse";
import { ArceringService } from "../../service/arcering.service";
import { LayerService } from "../../service/layer.service";
import { MapPrintService } from "../../service/print/map-print.service";
import { PrintService } from "../../service/print/print.service";
import { StreetAddressPipe } from "../../shared/utils/street-address.pipe";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-print",
    templateUrl: "./print.component.html",
})
export class PrintComponent {
    isPrinting = false;
    loadingIcon = faSpinner;

    @Input() resultaat: Resultaat;

    constructor(
        private arceringService: ArceringService,
        private printService: PrintService,
        private mapPrintService: MapPrintService,
        private layerService: LayerService,
        private streetAdressPipe: StreetAddressPipe
    ) {}

    print(): void {
        this.isPrinting = true;
        const title: string =
            this.streetAdressPipe.transform(this.resultaat.adresGegevens) +
            ", " +
            this.resultaat.adresGegevens.woonplaatsnaam;
        this.arceringService.arceringen
            .pipe(
                first(),
                switchMap((arceringen: Arcering) => {
                    if (
                        [...arceringen.vboFeatures, ...arceringen.bagFeatures, ...arceringen.kadastraleFeatures]
                            .length === 0
                    ) {
                        return of([] as Layer[]);
                    }
                    const center = getCenter(
                        [...arceringen.vboFeatures, ...arceringen.bagFeatures, ...arceringen.kadastraleFeatures][0]
                            .getGeometry()
                            .getExtent()
                    );
                    return combineLatest([
                        of(center),
                        this.layerService.getLayersForPrint(
                            arceringen.vboFeatures,
                            arceringen.bagFeatures,
                            arceringen.kadastraleFeatures
                        ),
                    ]);
                }),
                switchMap(([center, layers]: [Coordinate, Layer[]]) => {
                    const data: MapFishPrintRequest = this.createPrintRequest(center, title, layers);
                    return this.printService.sendPrintRequest(environment.printConfiguration, data);
                }),
                switchMap((printdata: PrintResponse) => this.printService.printIsReady(printdata.ref))
            )
            .subscribe((statusResponse) => {
                this.isPrinting = false;
                this.printService.getDownload(statusResponse.downloadURL, title);
            });
    }

    private createPrintRequest(center: Coordinate, title: string, layers?: Layer[]): MapFishPrintRequest {
        const attributes: Attributes = {
            ...PrintMapper.toPrintAddress(this.resultaat.adresGegevens),
            ...PrintMapper.toPrintWozwaarden(this.resultaat?.wozGegevens?.wozWaarden),
            ...PrintMapper.toPrintKenmerken(this.resultaat.pandGegevens, this.resultaat?.wozGegevens?.wozObject),
            title,
        };
        if (layers?.length > 0) {
            attributes.map = this.mapPrintService.createMap(
                environment.printMapScale,
                center,
                environment.mapName,
                layers
            );
        }

        return this.printService.createPrintRequest(
            layers?.length > 0 ? environment.printLayout : environment.printLayoutAlt,
            attributes
        );
    }

    isZichtbaar(): boolean {
        return this.resultaat?.wozGegevens?.wozObject?.wozobjectnummer !== undefined;
    }
}
