import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { EndpointsService } from "./endpoints.service";
import {lastValueFrom} from 'rxjs';

@Injectable()
export class EndpointsProvider {
    constructor(private endpointsService: EndpointsService) {}
    load(): Promise<boolean> {
        return lastValueFrom(this.endpointsService
            .load()
            .pipe(map((res) => !!res)));
    }
}
