<ng-container *ngIf="resultaat && !resultaat.noresult">
    <h3>Adres</h3>
    <address class="woz-address" *ngIf="hasAdress">
        <span id="adres-straat" *ngIf="resultaat.adresGegevens | streetAddress">
            {{ resultaat.adresGegevens | streetAddress }}</span
        >
        <span id="adres-koppel" *ngIf="resultaat?.adresGegevens?.postcode || resultaat?.adresGegevens?.woonplaatsnaam">
        </span>
        <span id="adres-postcode" *ngIf="resultaat?.adresGegevens?.postcode">
            {{ resultaat.adresGegevens.postcode }}</span
        >
        <span id="adres-woonplaats" *ngIf="resultaat?.adresGegevens?.woonplaatsnaam">{{
            resultaat.adresGegevens.woonplaatsnaam
        }}</span>
    </address>
    <span *ngIf="!hasAdress">
        <i>Geen adres beschikbaar</i>
    </span>
    <h3>WOZ-waarde</h3>
    <ng-container *ngIf="resultaat.wozGegevens">
        <table *ngIf="resultaat.wozGegevens.wozWaarden.length > 0" class="woz-table">
            <thead>
                <tr>
                    <th>Peildatum</th>
                    <th>WOZ-waarde</th>
                </tr>
            </thead>
            <tbody>
                <tr class="waarden-row" *ngFor="let waarde of showAllWaarden ? waarden : waardenPreview">
                    <td class="wozwaarde-datum">
                        {{ waarde.peildatum | date: "shortDate" }}
                    </td>
                    <td class="wozwaarde-waarde" *ngIf="waarde.vastgesteldeWaarde > 0">
                        {{ waarde.vastgesteldeWaarde | number: "4.0-0" }} euro
                    </td>
                    <td class="wozwaarde-waarde" *ngIf="waarde.vastgesteldeWaarde === 0">
                        {{ waarde.vastgesteldeWaarde }} euro
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="waarden__footer" *ngIf="resultaat?.wozGegevens?.wozWaarden?.length > maxWaarden">
            <button
                type="button"
                class="suggestion__button"
                id="waarden-show-all"
                *ngIf="!showAllWaarden"
                (click)="showAllWaarden = true"
            >
                <span class="suggestion__link">&gt; Alles weergeven</span>
            </button>
            <button
                type="button"
                class="suggestion__button"
                id="waarden-show-less"
                *ngIf="showAllWaarden"
                (click)="showAllWaarden = false"
            >
                <span class="suggestion__link">&lt; Minder weergeven</span>
            </button>
        </div>
    </ng-container>

    <div id="woz-leeg" *ngIf="!resultaat.wozGegevens">
        <p>Voor dit pand is geen WOZ-waarde beschikbaar. Oorzaak:</p>
        <p>Alleen WOZ-waarden van woningen zijn openbaar. Dit pand is niet (meer) of deels in gebruik als woning.</p>
    </div>

    <div id="woz-waarden-leeg" *ngIf="resultaat.wozGegevens && resultaat.wozGegevens.wozWaarden.length === 0">
        <p>Voor dit pand is geen WOZ-waarde beschikbaar. Oorzaak:</p>
        <p>Voor deze woning is nog geen WOZ-waarde beschikbaar.</p>
    </div>

    <ng-container *ngIf="resultaat.wozGegevens">
        <h3>WOZ-gegevens</h3>
        <dl class="woz-object-nummer">
            <dt>Identificatie:</dt>
            <dd id="kenmerk-wozobjectnummer">
                {{ resultaat.wozGegevens.wozObject?.wozobjectnummer | prefix: 12:"0" }}
            </dd>
        </dl>
        <dl class="grond-oppervlakte" *ngIf="resultaat.wozGegevens.wozObject?.grondoppervlakte">
            <dt>Grondoppervlakte:</dt>
            <dd id="kenmerk-grondoppervlakte">{{ resultaat.wozGegevens.wozObject?.grondoppervlakte }}m<sup>2</sup></dd>
        </dl>
    </ng-container>

    <ng-container
        *ngIf="
            resultaat.pandGegevens?.bouwjaar ||
            resultaat.pandGegevens?.gebruiksdoel ||
            resultaat.pandGegevens?.oppervlakte ||
            adresseerbaarobjectid ||
            nummeraanduidingid
        "
    >
        <h3 *ngIf="hasBagKenmerken">Kenmerken</h3>
        <dl>
            <ng-container *ngIf="resultaat.pandGegevens?.bouwjaar">
                <dt>Bouwjaar:</dt>
                <dd id="kenmerk-bouwjaar">{{ resultaat.pandGegevens.bouwjaar }}</dd>
            </ng-container>
            <ng-container *ngIf="resultaat.pandGegevens?.gebruiksdoel">
                <dt>Gebruiksdoel:</dt>
                <dd id="kenmerk-gebruiksdoel">
                    {{ resultaat.pandGegevens.gebruiksdoel }}
                </dd>
            </ng-container>
            <ng-container *ngIf="resultaat.pandGegevens?.oppervlakte">
                <dt>Oppervlakte:</dt>
                <dd id="kenmerk-oppervlakte">{{ resultaat.pandGegevens.oppervlakte }}m<sup>2</sup></dd>
            </ng-container>
            <ng-container *ngIf="adresseerbaarobjectid">
                <dt>Adresseerbaar object:</dt>
                <dd>
                    <a
                        id="link-adresseerbaarobjectid"
                        [href]="
                            'https://bagviewer.kadaster.nl/lvbag/bag-viewer/index.html#?searchQuery=' +
                            adresseerbaarobjectid
                        "
                        [attr.aria-label]="adresseerbaarobjectid + ' (Opent in nieuw venster)'"
                        target="_blank"
                    >
                        <span>
                            {{ adresseerbaarobjectid }}
                            <fa-icon [icon]="faExternalLink"></fa-icon>
                        </span>
                    </a>
                </dd>
            </ng-container>
            <ng-container *ngIf="nummeraanduidingid">
                <dt>Nummeraanduiding:</dt>
                <dd>
                    <a
                        id="link-nummeraanduidingid"
                        [href]="
                            'https://bagviewer.kadaster.nl/lvbag/bag-viewer/index.html#?searchQuery=' +
                            nummeraanduidingid
                        "
                        [attr.aria-label]="nummeraanduidingid + ' (Opent in nieuw venster)'"
                        target="_blank"
                    >
                        <span>
                            {{ nummeraanduidingid }}
                            <fa-icon [icon]="faExternalLink"></fa-icon>
                        </span>
                    </a>
                </dd>
            </ng-container>
        </dl>
    </ng-container>
    <div *ngIf="hasNoLocatie | async" id="no-locatie-message">
        <p>
            <em>
                Wij hebben een resultaat gevonden voor uw zoekopdracht, echter is hiervoor geen locatie op de kaart
                bekend omdat er geen BAG-gegevens gekoppeld zijn in de LV WOZ.
            </em>
        </p>
    </div>

    <app-print [resultaat]="resultaat"></app-print>
</ng-container>
<ng-container *ngIf="resultaat && resultaat.noresult">
    <em>Geen resultaat beschikbaar</em>
</ng-container>
