<a *ngIf="hamburger" data-id="totstandkoming-link" [routerLink]="['totstandkoming']">Totstandkoming WOZ-waarde</a>

<a *ngIf="hamburger" data-id="veelgesteldevragen-link" [routerLink]="['veelgesteldevragen']">Veelgestelde vragen</a>

<a *ngIf="hamburger" data-id="werkingwozwaardeloket-link" [routerLink]="['werkingwozwaardeloket']">Werking</a>

<a *ngIf="hamburger" data-id="uitleggegevens-link" [routerLink]="['uitleggegevens']">Uitleg gegevens</a>

<a data-id="gebruiksvoorwaarden-link" [routerLink]="['gebruiksvoorwaarden']">Gebruiksvoorwaarden </a>

<a data-id="toegangkelijkheid-link" [routerLink]="['toegangkelijkheidsverklaring']">Toegankelijkheid</a>
