import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { InfoModalComponent } from "./info/info-modal/info-modal.component";

const routes: Routes = [
    { path: "gebruiksvoorwaarden", component: InfoModalComponent },
    { path: "totstandkoming", component: InfoModalComponent },
    { path: "veelgesteldevragen", component: InfoModalComponent },
    { path: "werkingwozwaardeloket", component: InfoModalComponent },
    { path: "uitleggegevens", component: InfoModalComponent },
    { path: "toegangkelijkheidsverklaring", component: InfoModalComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
