import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SearchEvent } from "../../model/dto/SearchEvent";
import { AdresseerbaarObjectSuggestion } from "../../model/dto/AdresseerbaarObjectSuggestion";

@Component({
    selector: "app-resultaat-suggesties",
    templateUrl: "./resultaat-suggesties.component.html",
    styleUrls: ["./resultaat-suggesties.component.scss"],
})
export class ResultaatSuggestiesComponent {
    numberOfSuggestions = 10;

    showAll = false;

    @Input() identifier: string;

    get suggestions(): AdresseerbaarObjectSuggestion[] {
        if (this.showAll) {
            return this._suggestions;
        }
        return this._suggestions.slice(0, this.numberOfSuggestions);
    }

    @Input()
    set suggestions(value: AdresseerbaarObjectSuggestion[]) {
        this._suggestions = value;
    }

    get hasMoreSuggestions(): boolean {
        return this._suggestions.length > this.numberOfSuggestions;
    }

    @Output()
    lookup: EventEmitter<SearchEvent> = new EventEmitter();

    @Output()
    indexSuggestion: EventEmitter<number> = new EventEmitter<number>();

    private _suggestions: AdresseerbaarObjectSuggestion[] = [];

    onLookup(suggestion: AdresseerbaarObjectSuggestion, idx: number): void {
        this.lookup.emit({
            wozobjectnummer: suggestion.id,
            adresseerbaarobjectId: suggestion.adresseerbaarObjectId?.toString(),
        });
        this.indexSuggestion.emit(idx + 1);
    }
}
