import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Feature } from "ol";
import { Observable } from "rxjs";
import { map, share } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { PrintMapper } from "../model/mapper/PrintMapper";
import { Layer } from "../model/print/Layer";
import { Matrix } from "../model/print/Matrix";
import { CrsConfigService } from "./print/crs-config.service";
import { Service, Theme } from "@kadaster/generieke-geo-componenten-dataset-tree";
import { Geometry } from "ol/geom";

@Injectable({
    providedIn: "root",
})
export class LayerService {
    constructor(private http: HttpClient, private crsConfigService: CrsConfigService) {}

    getLayers(): Observable<Theme[]> {
        return this.http.get<Theme[]>("assets/WozThemes.json").pipe(share());
    }

    getLayersForPrint(
        vboFeatures: Feature<Geometry>[],
        bagFeatures: Feature<Geometry>[],
        kadastraleFeatures: Feature<Geometry>[]
    ): Observable<Layer[]> {
        return this.getLayers().pipe(
            map((themes) => {
                const layers: Layer[] = [];
                const kaartTheme = themes.find((theme) => theme.themeName === "Kaart");
                const kaart = kaartTheme.datasets.find((dataset) => dataset.datasetName === "BGT Achtergrond");
                kaart.services.forEach((service) => {
                    layers.push(this.getLayersForService(service));
                });

                themes
                    .filter((theme) => theme.themeName !== "Kaart" && theme.themeName !== "Luchtfoto")
                    .forEach((theme) => {
                        theme.datasets.forEach((dataset) => {
                            dataset.services.forEach((service) => {
                                layers.push(this.getLayersForService(service));
                            });
                        });
                    });

                layers.push(this.getGeoJsonLayer(vboFeatures, bagFeatures, kadastraleFeatures));

                return layers.reverse();
            })
        );
    }

    private getGeoJsonLayer(
        vboFeatures: Feature<Geometry>[],
        bagFeatures: Feature<Geometry>[],
        kadastraleFeatures: Feature<Geometry>[]
    ): Layer {
        const bagStyle: { [key: string]: unknown } = {
            fillColor: environment.bagLayer.fill.substring(0, 7),
            fillOpacity: parseInt(environment.bagLayer.fill.slice(-2), 16) / 255,
            strokeColor: environment.bagLayer.stroke,
            strokeWidth: 1.5,
        };
        return {
            failOnError: true,
            type: "geojson",
            style: {
                styleProperty: "type",
                bag: bagStyle,
                vbo: {
                    ...bagStyle,
                    graphicName: "circle",
                    pointRadius: 3,
                },
                kadastraal: {
                    fillColor: environment.kadastraleLayer.fill.substring(0, 7),
                    fillOpacity: parseInt(environment.kadastraleLayer.fill.slice(-2), 16) / 255,
                    strokeColor: environment.kadastraleLayer.stroke,
                    strokeWidth: 0.5,
                },
            },
            geoJson: {
                type: "FeatureCollection",
                features: [
                    ...kadastraleFeatures.map((feat) => {
                        return PrintMapper.toPrintFeature(feat, "kadastraal");
                    }),
                    ...bagFeatures.map((feat) => {
                        return PrintMapper.toPrintFeature(feat, "bag");
                    }),
                    ...vboFeatures.map((feat) => {
                        return PrintMapper.toPrintFeature(feat, "vbo");
                    }),
                ],
            },
        };
    }

    private getLayersForService(service: Service): Layer {
        const layer: Layer = {
            failOnError: true,
            opacity: 1,
            baseURL: service.url,
            type: service.type,
        };

        switch (layer.type) {
            case "wmts":
                layer.layer = service.layers[0].technicalName;
                layer.requestEncoding = "KVP";
                layer.matrixSet = "EPSG:28992";
                layer.matrices = this.getMatrices();
                break;
            case "wms":
                layer.layers = service.layers.map((l) => l.technicalName);
                layer.customParams = { TRANSPARENT: true };
        }
        return layer;
    }

    private getMatrices(): Matrix[] {
        const rdNewCrsConfig = this.crsConfigService.getRdNewCrsConfig();
        const matrixSizes = rdNewCrsConfig.matrixSizes;
        const resolutions = rdNewCrsConfig.resolutions;
        return rdNewCrsConfig.matrixIds.map((matrixId: string, idx) => {
            const matrixSize = matrixSizes[idx];
            const resolution = resolutions[idx];
            return {
                identifier: matrixId,
                matrixSize: [matrixSize, matrixSize],
                topLeftCorner: [rdNewCrsConfig.extent[0], rdNewCrsConfig.extent[3]],
                scaleDenominator: resolution / 0.00028,
                tileSize: [256, 256],
            };
        });
    }
}
