import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Feature } from "ol";
import { GeoJSON } from "ol/format";
import { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { EndpointsService } from "../endpoints/endpoints.service";
import { WozKadastraalObject } from "../model/dto/WozKadastraalObject";
import { Geometry } from "ol/geom";

@Injectable({
    providedIn: "root",
})
export class KadastraleKaartService {
    constructor(private endpointsService: EndpointsService, private http: HttpClient) {}

    fetchKadastraalPerceelFeatures(kadastraleObjecten: WozKadastraalObject[]): Observable<Feature<Geometry>[]> {
        if (kadastraleObjecten && kadastraleObjecten.length > 0) {
            const query = `<wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" service="WFS" version="1.1.0" xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.0.0/WFS-transaction.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" outputFormat="application/json">
                                <wfs:Query typeName="perceel" xmlns:null="http://bag.geonovum.nl">
                                    <ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
                                        ${this.getKadastraalPerceelFilters(kadastraleObjecten)}
                                    </ogc:Filter>
                                </wfs:Query>
                            </wfs:GetFeature>`;
            return this.http.post<GeoJSONFeatureCollection>(this.endpointsService.kadastralekaartService, query).pipe(
                map((result) => {
                    if (!result.features || result.features.length === 0) {
                        return [];
                    }
                    const formatGeoJSON = new GeoJSON();
                    return formatGeoJSON.readFeatures(result);
                }),
                catchError(() => [])
            );
        }
        return of([]);
    }

    private getKadastraalPerceelFilters(kadastraleObjecten: WozKadastraalObject[]): string {
        if (kadastraleObjecten.length === 1) {
            return this.getKadastraalPerceelFilter(kadastraleObjecten[0]);
        } else {
            const filters: string = kadastraleObjecten.map((obj) => this.getKadastraalPerceelFilter(obj)).join();
            return `<ogc:Or>
                ${filters}
              </ogc:Or>`;
        }
    }

    private getKadastraalPerceelFilter(kadastraalObject: WozKadastraalObject): string {
        return `<ogc:And>
                <ogc:PropertyIsEqualTo>
                    <ogc:PropertyName>perceelNummer</ogc:PropertyName>
                    <ogc:Literal>${kadastraalObject.kadastraalPerceelNummer}</ogc:Literal>
                </ogc:PropertyIsEqualTo>
                <ogc:PropertyIsEqualTo>
                    <ogc:PropertyName>sectie</ogc:PropertyName>
                    <ogc:Literal>${kadastraalObject.kadastraleSectie}</ogc:Literal>
                </ogc:PropertyIsEqualTo>
                <ogc:PropertyIsEqualTo>
                    <ogc:PropertyName>AKRKadastraleGemeenteCodeWaarde</ogc:PropertyName>
                    <ogc:Literal>${kadastraalObject.kadastraleGemeenteCode}</ogc:Literal>
                </ogc:PropertyIsEqualTo>
            </ogc:And>`;
    }
}
