<div *ngIf="!canFilter; else filters">
    <p>Filteren op de kaart is alleen beschikbaar als u ver genoeg bent ingezoomd.</p>
</div>

<ng-template #filters>
    <form class="filter-form">
        <app-filter-input
            label="Bouwjaar:"
            identifier="bouwjaar"
            name="bouwjaar"
            [(ngModel)]="bouwjaar"
            [maxlength]="4"
        ></app-filter-input>
        <app-filter-input
            identifier="oppervlakte"
            label="Oppervlakte (m²):"
            name="oppervlakte"
            [(ngModel)]="oppervlakte"
            [separator]="true"
        ></app-filter-input>
        <app-filter-input
            identifier="wozwaarde"
            label="WOZ-waarde (€ ):"
            name="wozwaarde"
            [(ngModel)]="wozWaarde"
            [separator]="true"
        ></app-filter-input>

        <div class="filter-actions">
            <button class="btn btn--primary" (click)="applyFilters()" id="button-filter-toepassen">
                {{ applyButtonLabel }}
            </button>
            <button id="button-filter-resetten" class="btn btn--link" (click)="resetFilters()" *ngIf="filterActive">
                Filters legen
            </button>
        </div>
    </form>
    <fa-icon
        [icon]="loadingIcon"
        spin="true"
        title="Gegevens worden geladen"
        aria-label="Gegevens worden geladen"
        class="fa-2xl"
        *ngIf="loadingResults"
    ></fa-icon>
    <span id="filter-geen-resultaat" *ngIf="!hasSuggestions && filterActive && !loadingResults">
        Geen resultaat gevonden
    </span>
</ng-template>
