<div class="map" [class.map--dataset-open]="datasetOpen" [attr.x-loading]="loading">
    <ggc-map
        [mapName]="mapName"
        [minZoomlevel]="minZoomLevel"
        [maxZoomlevel]="maxZoomLevel"
        (events)="onMapEvent($event)"
        [mapTabIndex]="0"
        class="focus_map"
    ></ggc-map>
</div>
