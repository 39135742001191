import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: "input[appNumericInput]",
})
export class NumericInputDirective {
    private nl: Intl.NumberFormat;

    @Input() format?: boolean;

    @Input() maxLength: number = Number.MAX_VALUE;

    constructor(private elemRef: ElementRef<HTMLInputElement>) {
        this.nl = new Intl.NumberFormat("NL-nl");
    }

    @HostListener("keypress", ["$event"])
    onKeyPress(event: KeyboardEvent): boolean {
        return /^(\d*$)/g.test(event.key);
    }

    @HostListener("paste")
    onPaste(): void {
        setTimeout(() => {
            this.elemRef.nativeElement.value = this.elemRef.nativeElement.value.replace(/\D/g, "");
        }, 100);
    }

    @HostListener("blur")
    onBlur(): void {
        const elem = this.elemRef.nativeElement;

        if (elem.type === "number") {
            const oldValue: string = elem.value;
            elem.type = "text";
            if (oldValue) {
                const parsedValue: number = parseInt(oldValue.substring(0, this.maxLength), 10);
                elem.value = this.format ? this.nl.format(parsedValue) : parsedValue.toString();
            }
        }
    }

    @HostListener("focus")
    onFocus(): void {
        const elem = this.elemRef.nativeElement;

        if (elem.type === "text") {
            const oldValue: string = elem.value;
            elem.type = "number";
            if (this.format && oldValue) {
                elem.value = parseInt(oldValue.replace(/\D/g, "")).toString();
            }
        }
    }
}
