import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-uitleg-gegevens",
    templateUrl: "./uitleg-gegevens.component.html",
    styleUrls: ["./uitleg-gegevens.component.scss"],
})
export class UitlegGegevensComponent implements AfterViewInit {
    faExternalLink = faExternalLinkAlt;

    @Output()
    close: EventEmitter<void> = new EventEmitter();

    @ViewChild("gegevensWozWaarde")
    wozWaarde: ElementRef;

    @ViewChild("gegevensWoz")
    woz: ElementRef;

    @ViewChild("gegevensIdentificatie")
    identificatie: ElementRef;

    @ViewChild("gegevensGrondoppervlakte")
    grondoppervlakte: ElementRef;

    @ViewChild("gegevensTaxatie")
    taxatie: ElementRef;

    @ViewChild("gegevensKenmerken")
    kenmerken: ElementRef;

    @ViewChild("gegevensBouwjaar")
    bouwjaar: ElementRef;

    @ViewChild("gegevensGebruiksdoel")
    gebruiksdoel: ElementRef;

    @ViewChild("gegevensOppervlakte")
    oppervlakte: ElementRef;

    @ViewChild("gegevensAot")
    aot: ElementRef;

    @ViewChild("gegevensNum")
    num: ElementRef;

    private headers = {};

    ngAfterViewInit(): void {
        this.headers = {
            wozWaarde: this.wozWaarde,
            woz: this.woz,
            identificatie: this.identificatie,
            grondoppervlakte: this.grondoppervlakte,
            taxatie: this.taxatie,
            kenmerken: this.kenmerken,
            bouwjaar: this.bouwjaar,
            gebruiksdoel: this.gebruiksdoel,
            oppervlakte: this.oppervlakte,
            aot: this.aot,
            num: this.num,
        };
    }

    onClose(): void {
        this.close.emit();
    }

    navTo(childRef: string): void {
        const header: ElementRef = this.headers[childRef];
        if (header) {
            header.nativeElement.scrollIntoView();
        }
    }
}
