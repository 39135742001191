import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MapService } from "@kadaster/generieke-geo-componenten-map";
import { environment } from "../../environments/environment";
import { SearchEvent } from "../model/dto/SearchEvent";
import { WozService } from "../service/woz.service";
import {
    AdditionalSuggestionSourceService,
    SearchComponentEvent,
    SearchComponentEventTypes,
} from "@kadaster/generieke-geo-componenten-search";

@Component({
    selector: "app-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
    providers: [{ provide: AdditionalSuggestionSourceService, useClass: WozService }],
})
export class SearchComponent implements OnInit {
    numberOfSuggestions = 10;
    term = "";

    @Output() search = new EventEmitter<SearchEvent>();

    constructor(private mapService: MapService) {}

    ngOnInit(): void {
        const parameters = new URLSearchParams(window.location.search);
        if (parameters.has("wozid") && parameters.get("wozid").match(/\d{12}/)) {
            this.search.emit({
                wozobjectnummer: parameters.get("wozid"),
            });
        } else if (parameters.has("locatie") && parameters.get("locatie").match(/\d{4}(\s|\+)?\w{2}.*/)) {
            this.term = parameters.get("locatie");
        }
    }

    searchEvent($event: SearchComponentEvent): void {
        if ($event.type === SearchComponentEventTypes.SEARCH_RESULT) {
            if ($event.value?.docs.length > 0 && $event.value?.docs[0].source === "wozService") {
                this.search.emit({
                    wozobjectnummer: $event.value.docs[0].id,
                });
            } else {
                this.mapService.zoomToPdokResult($event, environment.mapName);
                const nummeraanduidingId = this.getNummeraanduidingId($event);
                const searchAdresseerbaarObjectId = this.getAdresseerbaarobjectId($event);
                if (nummeraanduidingId && searchAdresseerbaarObjectId) {
                    this.search.emit({
                        nummeraanduidingId,
                        searchAdresseerbaarObjectId,
                    });
                } else if ($event.value?.docs.length == 1) {
                    const doc = $event.value?.docs[0];
                    if (doc.woonplaatsnaam && doc.straatnaam) {
                        const query = [doc.straatnaam, doc.woonplaatsnaam].filter((term) => !!term).join(",");
                        this.search.emit({ straat: query });
                    }
                } else {
                    this.search.emit({});
                }
            }
        }
    }

    private getNummeraanduidingId($event: SearchComponentEvent): string | undefined {
        if ($event.value?.docs.length > 0) {
            return $event.value.docs[0]?.nummeraanduiding_id;
        }
    }

    private getAdresseerbaarobjectId($event: SearchComponentEvent): string | undefined {
        if ($event.value?.docs.length > 0) {
            return $event.value.docs[0]?.adresseerbaarobject_id;
        }
    }
}
