<app-modal (close)="onClose()" title="Uitleg gegevens">
    <p>
        U leest hier een uitleg over de gegevens die het WOZ-waardeloket toont. U kunt dit helemaal doorlezen, of een
        onderwerp uit de lijst kiezen:
    </p>
    <ul>
        <li>
            <button type="button" class="btn btn-link" id="gegevens-nav-wozwaarde" (click)="navTo('wozWaarde')">
                <span class="btn-text">WOZ-waarde en waardepeildatum</span>
            </button>
        </li>
        <li>
            <button type="button" class="btn btn-link" id="gegevens-nav-woz" (click)="navTo('woz')">
                <span class="btn-text">WOZ-gegevens</span>
            </button>
            <ul>
                <li>
                    <button
                        type="button"
                        class="btn btn-link"
                        id="gegevens-nav-identificatie"
                        (click)="navTo('identificatie')"
                    >
                        <span class="btn-text">Identificatie</span>
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        class="btn btn-link"
                        id="gegevens-nav-grondoppervlakte"
                        (click)="navTo('grondoppervlakte')"
                    >
                        <span class="btn-text">Grondoppervlakte</span>
                    </button>
                </li>
            </ul>
        </li>
        <li>
            <button type="button" class="btn btn-link" id="gegevens-nav-kenmerken" (click)="navTo('kenmerken')">
                <span class="btn-text">Kenmerken</span>
            </button>
            <ul>
                <li>
                    <button type="button" class="btn btn-link" id="gegevens-nav-bouwjaar" (click)="navTo('bouwjaar')">
                        <span class="btn-text">Bouwjaar</span>
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        class="btn btn-link"
                        id="gegevens-nav-gebruiksdoel"
                        (click)="navTo('gebruiksdoel')"
                    >
                        <span class="btn-text">Gebruiksdoel</span>
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        class="btn btn-link"
                        id="gegevens-nav-oppervlakte"
                        (click)="navTo('oppervlakte')"
                    >
                        <span class="btn-text">Oppervlakte</span>
                    </button>
                </li>
                <li>
                    <button type="button" class="btn btn-link" id="gegevens-nav-aot" (click)="navTo('aot')">
                        <span class="btn-text">Adresseerbaar object</span>
                    </button>
                </li>
                <li>
                    <button type="button" class="btn btn-link" id="gegevens-nav-num" (click)="navTo('num')">
                        <span class="btn-text">Nummeraanduiding</span>
                    </button>
                </li>
            </ul>
        </li>
    </ul>

    <h3 #gegevensWozWaarde>WOZ-waarde en waardepeildatum</h3>

    <p>
        WOZ staat voor Waardering Onroerende Zaken. De WOZ-waarde komt uit de Basisregistratie WOZ. Alleen de
        WOZ-waarden van woningen zijn openbaar. U ziet daarom geen WOZ-waarden van andere onroerende zaken.
    </p>
    <p>
        De gemeente bepaalt jaarlijks de WOZ-waarde. Het is de marktwaarde van de woning op 1 januari een jaar geleden.
        De hoogte van de WOZ-waarde is onder andere gebaseerd op verkoopprijzen en woningkenmerken.
    </p>
    <p>
        De Waarderingskamer ziet toe op de uitvoering van de WOZ-taxaties door de gemeenten. Meer informatie vindt u
        onder
        <a [routerLink]="['/totstandkoming']">Totstandkoming WOZ-waarde</a>.
    </p>
    <p>
        De waardepeildatum is de datum waarvoor de waarde is bepaald. De WOZ-waarde is de marktwaarde rond deze
        waardepeildatum, dus de prijs die naar verwachting betaald zou zijn als rond die datum de woning verkocht zou
        zijn. Deze peildatum ligt altijd een jaar vóór het jaar waarin de waarde voor de belastingheffing wordt
        gebruikt. Dus voor de belastingaanslag die u in 2022 krijgt, geldt de WOZ-waarde van de waardepeildatum 1
        januari 2021. En wanneer u in april 2022 de belastingaangifte over belastingjaar 2021 invult, gebruikt u dus de
        WOZ-waarde op peildatum 1 januari 2020.
    </p>

    <h3 #gegevensWoz>WOZ-gegevens</h3>

    <p>Naast de WOZ-waarden tonen we een aantal andere WOZ-gegevens in het WOZ-waardeloket.</p>

    <h4 #gegevensIdentificatie>Identificatie</h4>

    <p>
        De identificatie is het WOZ-objectnummer waaronder de woning in de Basisregistratie WOZ van de gemeente bekend
        is. Dit nummer ziet u bijvoorbeeld ook op het taxatieverslag.
    </p>

    <h4 #gegevensGrondoppervlakte>Grondoppervlakte</h4>

    <p>
        De grondoppervlakte van het WOZ-object uit de basisregistratie WOZ. Deze oppervlakte is de totale oppervlakte
        die behoort bij de woning, dus bijvoorbeeld de tuin plus de ondergrond van de woning. Dit komt overeen met de
        optelsom van de oppervlakte van alle bij de woning behorende (delen van) kadastrale percelen. Bij appartementen
        wordt geen grondoppervlakte getoond.
    </p>

    <h3 #gegevensKenmerken>Kenmerken</h3>

    <p>
        De kenmerken van de woning die worden getoond komen uit de Basisregistratie Adressen en Gebouwen (BAG). Voor de
        WOZ-taxatie door de gemeente worden meer kenmerken gebruikt. Niet alle kenmerken die de gemeente gebruikt bij de
        taxatie zijn beschikbaar in dit WOZ-waardeloket.
    </p>

    <h4 #gegevensBouwjaar>Bouwjaar</h4>

    <p>
        De Basisregistratie Adressen en gebouwen registreert het bouwjaar per "pand". U ziet het oorspronkelijke
        bouwjaar van het (hoofd)pand dat hoort bij de woning. Het bouwjaar dat is gebruikt voor de WOZ-taxatie is soms
        niet hetzelfde als het bouwjaar dat vermeld staat in de Basisregistratie Adressen en Gebouwen. Door aan- en
        verbouw kan een andere woning zijn ontstaan dan het oorspronkelijke pand. De WOZ-taxatie houdt ook rekening met
        verschillende bouwjaren van verschillende onderdelen. De gegevens die bij de taxatie zijn gebruikt, vindt u in
        het taxatieverslag van de woning dat de eigenaar en de gebruiker van de woning bijvoorbeeld via MijnOverheid kan
        inzien.
    </p>

    <h4 #gegevensGebruiksdoel>Gebruiksdoel</h4>

    <p>
        U ziet hier het gebruiksdoel waarvoor het gebouw of onderdeel van het gebouw (de Basisregistratie Adressen en
        Gebouwen noemt dit verblijfsobject) oorspronkelijk is gebouwd, of het gebruiksdoel waarvoor het bij een latere
        vergunning geschikt is gemaakt. Het huidige gebruik kan anders zijn dan het gebruiksdoel uit de vergunning. De
        WOZ-taxatie gaat uit van het daadwerkelijke huidige gebruik. Dus als er in een oorspronkelijk winkelpand wordt
        gewoond, wordt het pand gewaardeerd als een woning. Als een woning nu als winkel wordt gebruikt, wordt dit pand
        als een winkel gewaardeerd. U ziet in dat laatste geval geen WOZ-waarde, want alleen de WOZ-waarde van woningen
        is openbaar. Dit kan mogelijk verwarrend zijn, wanneer u wel als gebruiksdoel ‘woonfunctie’ ziet staan, maar
        geen WOZ-waarde ziet, met de melding dat alleen de WOZ-waarden van woningen openbaar zijn. Dat is helaas niet te
        voorkomen.
    </p>

    <h4 #gegevensOppervlakte>Oppervlakte</h4>

    <p>
        De oppervlakte die wordt getoond komt uit de Basisregistratie Adressen en Gebouwen en is de oppervlakte van het
        verblijfsobject. Het verblijfsobject komt in de meeste gevallen overeen met de woning, maar omvat bijvoorbeeld
        niet de oppervlakte van een externe berging of een garage. De oppervlakte in vierkante meters (m²) wordt gemeten
        volgens de definitie van de gebruiksoppervlakte voor woningen. Er wordt gemeten volgens een breed gebruikte
        meetinstructie: vrij vertaald is dit de oppervlakte van alle verdiepingen samen. Wat daarin niet meetelt zijn:
    </p>

    <ul>
        <li>vierkante meters met een stahoogte van minder dan 1,50 meter</li>
        <li>trapgaten</li>
        <li>leidingschachten</li>
        <li>vides en dergelijke</li>
    </ul>

    <p>
        U kunt deze meetinstructie vinden op de site van de
        <a href="https://www.waarderingskamer.nl/" target="_blank"
            ><span>Waarderingskamer<fa-icon [icon]="faExternalLink"></fa-icon> </span></a
        >.
    </p>

    <p>
        Voor de bepaling van de WOZ-waarde zijn nog andere oppervlakten belangrijk. Denk aan de oppervlakte van
        losstaande bergingen, een (dak)terras en de grond die bij de woning hoort. Deze oppervlakten zijn niet allemaal
        zichtbaar op dit loket. Welke oppervlakten voor de waardebepaling zijn gebruikt, vindt u in het taxatieverslag.
        De oppervlakte die u op dit loket ziet, kan (iets) afwijken van de oppervlakte in het taxatieverslag. Als
        eigenaar en/of gebruiker van de woning bekijkt u het taxatieverslag via
        <a href="https://mijn.overheid.nl/" target="_blank">
            <span>
                MijnOverheid
                <fa-icon [icon]="faExternalLink"></fa-icon> </span></a
        >. of op de site die uw gemeente of het samenwerkingsverband dat voor uw gemeente de WOZ-taxaties verzorgt
        daarvoor beschikbaar heeft gesteld.
    </p>

    <h4 #gegevensAot>Adresseerbaar object</h4>

    <p>
        De link Adresseerbaar object brengt u naar de BAG-viewer (BAG = Basisregistratie Adressen en Gebouwen) met meer
        gegevens over de manier waarop uw woning in deze registratie is vastgelegd. In de BAG zal een woning meestal
        zijn gedefinieerd als een verblijfsobject. In sommige gevallen zal sprake zijn van een ligplaats of standplaats.
        Het nummer is een BAG-identificatie die hoort bij de gekozen woning.
    </p>

    <h4 #gegevensNum>Nummeraanduiding</h4>

    <p>
        De link Nummeraanduiding brengt u ook naar de BAG-viewer (BAG = Basisregistratie Adressen en Gebouwen).
        Nummeraanduiding is in deze registratie de officiële naam voor een adres. In de BAG-viewer vindt u meer gegevens
        over dit adres. Dit is een BAG-identificatie die hoort bij de gekozen woning.
    </p>

    <p>
        Voor vragen en/of bezwaar op de getoonde gegevens in het WOZ-waardeloket verwijzen wij u door naar de
        betreffende gemeente of het samenwerkingsverband die voor deze gemeente de WOZ-taxaties verzorgt. Via de
        hiervoor genoemde BAG-viewer kunt u ook meldingen doen over mogelijke onjuiste kenmerken voor een woning. Hoe
        dit werkt in deze BAG-viewer leest u onder "Terugmelding".
    </p>
</app-modal>
