<div class="filters" [id]="identifier">
    <fieldset class="no_border">
        <legend class="filters__legend">{{ label }}</legend>
        <div class="filters__group">
            <label class="filters__filter" [for]="identifier + '-min'">
                <span class="filters__label">Van</span>
            </label>

            <input
                appNumericInput
                [format]="separator"
                [maxLength]="maxlength"
                [id]="identifier + '-min'"
                [name]="identifier + '-min'"
                [value]="minValue"
                (change)="minChange($event)"
                type="text"
                min="0"
                step="1"
                class="filters__input align-right"
            />
        </div>

        <div class="filters__group">
            <label class="filters__filter" aria-label="tot en met" [for]="identifier + '-max'">
                <span aria-hidden="true" class="filters__label"> <sup>t</sup>/<sub>m</sub> </span>
            </label>

            <input
                appNumericInput
                [format]="separator"
                [maxLength]="maxlength"
                [id]="identifier + '-max'"
                [name]="identifier + '-max'"
                [value]="maxValue"
                (change)="maxChange($event)"
                type="text"
                min="0"
                step="1"
                class="filters__input align-right"
            />
        </div>
    </fieldset>
</div>
