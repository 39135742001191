import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "prefix",
})
export class PrefixPipe implements PipeTransform {
    transform(value: string | number, length: number, char: string): string {
        if (value.toString(10).length >= length) {
            return value.toString(10);
        }
        return (char.repeat(length) + value).slice(-length);
    }
}
