<div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="titel" (click)="onClose()" cdkTrapFocus>
    <div class="modal__card" (click)="$event.stopPropagation()">
        <div class="modal__header">
            <div class="modal__header-buttons">
                <button
                    #closebutton
                    class="btn btn--transparent btn--square btn--close"
                    id="modal-close"
                    aria-label="Sluiten"
                    (click)="onClose()"
                >
                    <span class="icon icon--close icon--black"></span>
                </button>
            </div>
            <h2 id="titel">{{ title }}</h2>
        </div>
        <div class="modal__content" tabindex="0">
            <ng-content></ng-content>
        </div>
    </div>
</div>
