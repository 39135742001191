<div class="container">
    <app-voorscherm-modal class="vertical-center"></app-voorscherm-modal>
    <app-header></app-header>
    <main class="main">
        <app-search (search)="onSearch($event)"></app-search>
        <app-map [datasetOpen]="datasetOpen" (zoom)="onZoom($event)"></app-map>
        <app-sidebar
            [zoomLevel]="zoomLevel"
            [resultaat]="resultaat | async"
            [suggestions]="resultaatSuggesties | async"
            [searchResult]="searchResult"
            (lookup)="onLookup($event)"
        >
            <app-dataset-toggle
                [mode]="backgroundLayer | async"
                (click)="datasetOpen = true"
                [expanded]="datasetOpen"
            ></app-dataset-toggle>
        </app-sidebar>
        <app-dataset
            [attr.aria-expanded]="datasetOpen"
            [open]="datasetOpen"
            (close)="datasetOpen = false"
            (searchByPand)="onSearchByPand($event)"
            (searchByVBOs)="onSearchByVBOs($event)"
            (searchByKOZ)="onSearchByKOZ($event)"
        ></app-dataset>
    </main>
    <footer class="footer">
        <div class="footer__meta">
            <span id="footer-kaartlaag">{{ backgroundLayer | async }}</span>
            <ggc-map-details-container>
                <ggc-scale-line [mapName]="mapName"></ggc-scale-line>
                <span class="screen-reader-hidden">afstand per lijnstuk</span>
                <ggc-scale-denominator [mapName]="mapName"></ggc-scale-denominator>
                <span class="screen-reader-hidden">schaal van de kaart</span>
            </ggc-map-details-container>
        </div>
        <nav class="footer__nav">
            <app-nav></app-nav>
        </nav>
    </footer>
    <router-outlet></router-outlet>
</div>
