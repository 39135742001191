import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AdresseerbaarObjectSuggestion } from "../../model/dto/AdresseerbaarObjectSuggestion";
import { Resultaat } from "../../model/dto/Resultaat";
import { SearchEvent } from "../../model/dto/SearchEvent";
import { Zoom } from "../../model/dto/Zoom";
import { ArceringService } from "../../service/arcering.service";
import { OpenedBlocks, UiService } from "../../service/ui.service";
import { finalize } from "rxjs/operators";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnChanges {
    @Input() zoomLevel: Zoom;

    @Output() lookup: EventEmitter<SearchEvent> = new EventEmitter();

    openedBlocks: BehaviorSubject<OpenedBlocks>;

    sidebarOpen: Subject<boolean>;

    indexSuggestion: number;

    resultFromSuggestions: boolean;

    searching: boolean;

    @Input()
    set resultaat(resultaat: Resultaat) {
        if (resultaat) {
            this.onToggle("suggestions", false);
        }
        this._resultaat = resultaat;
    }

    get resultaat(): Resultaat {
        return this._resultaat;
    }

    @Input()
    set searchResult(searchResult: Observable<[Resultaat, AdresseerbaarObjectSuggestion[]]>) {
        if (searchResult) {
            this.searching = true;
            searchResult.pipe(finalize(() => (this.searching = false))).subscribe(() => {
                // NOOP
            });
        }
    }

    private _suggestions: AdresseerbaarObjectSuggestion[];

    private _resultaat: Resultaat;

    @Input()
    set suggestions(suggestions: AdresseerbaarObjectSuggestion[]) {
        this.arceringService.clearLayer("filter");
        if (suggestions?.length > 1) {
            this._suggestions = suggestions;
        } else {
            this._suggestions = [];
        }
    }

    get suggestions(): AdresseerbaarObjectSuggestion[] {
        return this._suggestions;
    }

    constructor(private arceringService: ArceringService, private ui: UiService) {
        this.openedBlocks = ui.openedBlocks$;
        this.sidebarOpen = ui.sidebarOpen$;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resultaat) {
            this.onToggle("result", !!this.resultaat);
        }
        if (changes.suggestions) {
            this.onToggle("suggestions", !!this.suggestions && this.suggestions.length > 1);
        }
    }

    onFilter(results: AdresseerbaarObjectSuggestion[]): void {
        this._suggestions = results ? results : [];
        this.onToggle("suggestions", !!this.suggestions && this.suggestions.length > 1);
    }

    onLookup(event: SearchEvent): void {
        this.lookup.emit(event);
        this.onToggle("filterResult", false);
    }

    onCloseSuggestions(): void {
        this.onToggle("suggestions", false);
        this.suggestions = [];
        if (!this.resultaat) {
            this.arceringService.clearArcering();
        }
    }

    onToggle(block: "result" | "suggestions" | "filterResult" | "filters", open: boolean): void {
        this.ui.toggleBlock(block, open);
    }

    onCloseResult(): void {
        this.onToggle("result", false);
        this.resultaat = null;
        this.arceringService.clearArcering();
    }

    onIndex($event: number): void {
        this.indexSuggestion = $event;
    }
}
