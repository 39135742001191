import { Component, EventEmitter, Output } from "@angular/core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-veelgestelde-vragen",
    templateUrl: "./veelgestelde-vragen.component.html",
    styleUrls: ["./veelgestelde-vragen.component.scss"],
})
export class VeelgesteldeVragenComponent {
    faExternalLink = faExternalLinkAlt;

    @Output()
    close: EventEmitter<void> = new EventEmitter();

    onClose(): void {
        this.close.emit();
    }
}
