import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MapComponentEvent, MapComponentEventTypes, MapService } from "@kadaster/generieke-geo-componenten-map";
import { environment } from "../../environments/environment";
import { Zoom } from "../model/dto/Zoom";
import { ArceringService } from "../service/arcering.service";

@Component({
    selector: "app-map",
    templateUrl: "./map.component.html",
    styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, AfterViewInit {
    loading = true;
    minZoomLevel: number;
    maxZoomLevel: number;
    mapName = environment.mapName;
    zoomLevels = [3, 5, 7, 9, 11, 13, 15];
    zoomInText = "Zoom in";
    zoomOutText = "Zoom uit";

    @Input() datasetOpen = false;
    @Output()
    zoom: EventEmitter<Zoom> = new EventEmitter<Zoom>();

    constructor(private mapService: MapService, private arceringService: ArceringService) {}

    ngOnInit(): void {
        this.minZoomLevel = this.zoomLevels.reduce((min, current) => {
            current = Math.min(min, current);
            return current;
        }, 99);
        this.maxZoomLevel = this.zoomLevels.reduce((max, current) => {
            current = Math.max(max, current);
            return current;
        }, 0);
        this.arceringService.arceringen.subscribe((arcering) => {
            this.arceringService.clearLayer("kadastraal");
            this.arceringService.clearLayer("bag");
            if (arcering.vboFeatures.length <= 1 || arcering.zoom >= environment.highlightMaxZoom) {
                this.arceringService.addFeaturesToLayer(arcering.vboFeatures, "bag");
            }
            if (arcering.zoom >= environment.highlightMaxZoom) {
                this.arceringService.addFeaturesToLayer(arcering.bagFeatures, "bag");
                this.arceringService.addFeaturesToLayer(arcering.kadastraleFeatures, "kadastraal");
            }
        });
    }

    ngAfterViewInit(): void {
        const zoomInButton = document.getElementsByClassName("ol-zoom-in")[0];
        zoomInButton.setAttribute("title", this.zoomInText);
        zoomInButton.setAttribute("aria-label", this.zoomInText);

        const zoomOutButton = document.getElementsByClassName("ol-zoom-out")[0];
        zoomOutButton.setAttribute("title", this.zoomOutText);
        zoomOutButton.setAttribute("aria-label", this.zoomOutText);
    }

    onMapEvent($event: MapComponentEvent): void {
        if ($event.type === MapComponentEventTypes.ZOOMEND || $event.type === MapComponentEventTypes.ZOOMENDLOCATION) {
            this.zoom.emit({
                zoom: this.mapService.getMap(this.mapName).getView().getZoom(),
            });
            this.arceringService.setZoomlevel(this.mapService.getMap(this.mapName).getView().getZoom());
        } else if ($event.type === MapComponentEventTypes.MAPINITIALIZED) {
            // Todo: Als de GGC componenten het ondersteunen, de opacity via de templates / ggc componenten laten lopen
            this.mapService
                .getMap(this.mapName)
                .getLayerGroup()
                .on("change", () => {
                    this.mapService
                        .getMap(this.mapName)
                        .getLayers()
                        .forEach((layer) => {
                            try {
                                const params = layer.get("source").getParams();
                                if (params?.layers && ["pand", "ligplaats", "standplaats"].includes(params.layers)) {
                                    layer.set("opacity", 0.5);
                                }
                            } catch (e) {
                                // source.getParams() not available
                            }
                        });
                });
        } else if ($event.type === MapComponentEventTypes.LOADING && $event.mapName === this.mapName) {
            this.loading = $event.value as boolean;
        }
    }
}
