<app-modal (close)="onClose()" title="Welkom">
    <p>Met het WOZ-waardeloket kunt u de WOZ-waarde van de woning van uzelf of andere woningen raadplegen.</p>
    <p>
        De gemeente stelt de WOZ-waarde vast en is daar verantwoordelijk voor. Bij vragen over de waarde kunt u dus bij
        uw gemeente terecht of bij het samenwerkingsverband dat voor uw gemeente de WOZ-taxaties verzorgt.
    </p>
    <p>
        Het WOZ-waardeloket is bedoeld voor het individueel raadplegen van afzonderlijke woningen voor burgers en niet
        voor het massaal of geautomatiseerd downloaden en/of onttrekken van gegevens. U kunt daarom een beperkt aantal
        woningen opvragen binnen een tijdsperiode.
    </p>
    <p>
        Door verder te gaan gaat u akkoord met de
        <a href="#" (click)="onTerms()" id="voorscherm-gebruiksvoorwaarden-link">Gebruiksvoorwaarden</a>.
    </p>
    <div class="disclaimer__button-bar">
        <button class="btn btn--primary" id="kaart-bekijken-btn" (click)="onClose()">Ga verder</button>
    </div>
</app-modal>
