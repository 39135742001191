import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { interval, Observable } from "rxjs";
import { filter, startWith, switchMap, take } from "rxjs/operators";
import { Attributes } from "../../model/print/Attributes";
import { MapFishPrintRequest } from "../../model/print/MapFishPrintRequest";
import { PrintResponse } from "../../model/print/PrintResponse";
import { StatusResponse } from "../../model/print/StatusResponse";
import { EndpointsService } from "../../endpoints/endpoints.service";

@Injectable({
    providedIn: "root",
})
export class PrintService {
    private readonly headers: HttpHeaders;

    constructor(
        private endpointsService: EndpointsService,
        private http: HttpClient
    ) {

    }

    createPrintRequest(template: string, attributes: Attributes): MapFishPrintRequest {
        return {
            layout: template,
            attributes,
            outputFilename: "report.pdf",
        };
    }

    sendPrintRequest(configurationName: string, data: MapFishPrintRequest): Observable<PrintResponse> {
        return this.http.post<PrintResponse>(`${this.endpointsService.wozService}/${configurationName}`, data, {
            headers: this.headers,
        });
    }

    printIsReady(printId: string): Observable<StatusResponse> {
        return interval(1400).pipe(
            startWith(0),
            switchMap(() => this.poll(printId)),
            filter((statusResponse) => statusResponse.done === true),
            take(1)
        );
    }

    getDownload(url: string, title: string): void {
        const headers = new HttpHeaders().set("Content-Type", "application/pdf");
        const opts = {
            responseType: "blob" as const,
            observe: "response" as const,
            headers,
        };
        this.http.get(this.endpointsService.wozService + url, opts).subscribe((x: HttpResponse<Blob>) => {
            saveAs(x.body, `WOZ-Waardeloket - ${title}.pdf`);
        });
    }

    private poll(printId: string): Observable<StatusResponse> {
        return this.http.get<StatusResponse>(`${this.endpointsService.wozService}/print/status/${printId}.json`, { headers: this.headers });
    }
}
