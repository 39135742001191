import { Adres } from "../dto/Adres";
import { BagGegevens } from "../dto/BagGegevens";
import { Resultaat } from "../dto/Resultaat";
import { SearchEvent } from "../dto/SearchEvent";
import { WozGegevens } from "../dto/WozGegevens";
import { AdresMapper } from "./AdresMapper";

export class ResultaatMapper {
    static mapResult([wozwaarde, pandProperties]: [WozGegevens, BagGegevens], event: SearchEvent): Resultaat {
        if (!wozwaarde && !pandProperties) {
            return null;
        }
        let adres: Adres;
        if (wozwaarde) {
            adres = AdresMapper.fromWozObject(wozwaarde.wozObject);
        } else if (pandProperties) {
            adres = AdresMapper.fromPandGegevens(pandProperties);
        }

        if (pandProperties?.gebruiksdoel) {
            pandProperties.gebruiksdoel = pandProperties.gebruiksdoel.split(",").join(", ");
        }
        return {
            adresGegevens: adres,
            wozGegevens: wozwaarde,
            pandGegevens: pandProperties,
            nummeraanduidingid: wozwaarde?.wozObject?.nummeraanduidingid?.toString(10) || event.nummeraanduidingId,
            adresseerbaarobjectid:
                wozwaarde?.wozObject?.adresseerbaarobjectid?.toString(10) || event.adresseerbaarobjectId,
        } as Resultaat;
    }

    static mapBagGegevens(bagGegevens: BagGegevens): Resultaat {
        const adres: Adres = AdresMapper.fromBagResult(bagGegevens);
        if (bagGegevens?.gebruiksdoel) {
            bagGegevens.gebruiksdoel = bagGegevens.gebruiksdoel.split(",").join(", ");
        }
        return {
            adresGegevens: adres,
            wozGegevens: null,
            pandGegevens: bagGegevens,
            nummeraanduidingid: null,
            adresseerbaarobjectid: bagGegevens?.identificatie,
        } as Resultaat;
    }
}
