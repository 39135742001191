import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule, registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { GgcMapModule } from "@kadaster/generieke-geo-componenten-map";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DatasetToggleComponent } from "./dataset-toggle/dataset-toggle.component";
import { DatasetComponent } from "./dataset/dataset.component";
import { WmsDatasetComponent } from "./dataset/wms-dataset/wms-dataset.component";
import { EndpointsProvider } from "./endpoints/endpoints.provider";
import { endpointsProviderFactory } from "./endpoints/endpoints.provider.factory";
import { HeaderComponent } from "./header/header.component";
import { InfoModule } from "./info/info.module";
import { MapComponent } from "./map/map.component";
import { NavComponent } from "./nav/nav.component";
import { SearchComponent } from "./search/search.component";
import { SharedModule } from "./shared/shared.module";
import { SidebarModule } from "./sidebar/sidebar.module";
import { GgcSearchModule } from "@kadaster/generieke-geo-componenten-search";
import { GgcDatasetTreeModule } from "@kadaster/generieke-geo-componenten-dataset-tree";
import { GgcFeatureInfoModule } from "@kadaster/generieke-geo-componenten-feature-info";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

registerLocaleData(localeNl, "nl-NL");

@NgModule({
    declarations: [
        AppComponent,
        MapComponent,
        DatasetComponent,
        DatasetToggleComponent,
        SearchComponent,
        NavComponent,
        HeaderComponent,
        WmsDatasetComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        CommonModule,
        GgcMapModule,
        GgcSearchModule,
        GgcDatasetTreeModule,
        GgcFeatureInfoModule,
        FormsModule,
        SharedModule,
        SidebarModule,
        SharedModule,
        OverlayModule,
        InfoModule,
        FontAwesomeModule,
    ],
    providers: [
        EndpointsProvider,
        { provide: LOCALE_ID, useValue: "nl-NL" },
        {
            provide: APP_INITIALIZER,
            useFactory: endpointsProviderFactory,
            deps: [EndpointsProvider],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
