import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MapService } from "@kadaster/generieke-geo-componenten-map";
import { Feature } from "ol";
import { Extent } from "ol/extent";
import { GeoJSON } from "ol/format";
import { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import { forkJoin, from, Observable, of } from "rxjs";
import { catchError, map, switchMap, toArray, mergeMap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { EndpointsService } from "../endpoints/endpoints.service";
import { Range } from "../filter/filter-input/filter-input.component";
import { BagGegevens } from "../model/dto/BagGegevens";
import { VerblijfsObject } from "../model/dto/VerblijfsObject";
import { PrefixPipe } from "../shared/utils/prefix.pipe";
import { ArceringService } from "./arcering.service";
import { chunkIdentifiers } from "../shared/utils/http-utils";

@Injectable({
    providedIn: "root",
})
export class BagService {
    constructor(
        private arceringService: ArceringService,
        private endpointsService: EndpointsService,
        private httpClient: HttpClient,
        private mapService: MapService,
        private prefixPipe: PrefixPipe
    ) {}

    arceerPandCentra(adresseerbaarObjectIds: string[]): void {
        this.getAllPandGegevens(adresseerbaarObjectIds).subscribe((gegevens: BagGegevens[]) => {
            const centerGeos: Feature<Geometry>[] = [
                ...new Set(gegevens.map((gegeven) => gegeven.center_geo).reduce((acc, geos) => [...acc, ...geos], [])),
            ];
            if (centerGeos.length > 0) {
                this.arceringService.setVboFeatures(centerGeos);
            }
        });
    }

    getAllPandGegevens(adresseerbaarObjectId: string[]): Observable<BagGegevens[]> {
        const identificaties: string[] = adresseerbaarObjectId.map((id) => this.prefixPipe.transform(id, 16, "0"));

        const ligplaatsIdentificaties: string[] = identificaties.filter((id) => id.substring(4, 6) === "02");

        const standplaatsIdentificaties: string[] = identificaties.filter((id) => id.substring(4, 6) === "03");

        const pandIdentificaties: string[] = identificaties
            .filter((id) => !ligplaatsIdentificaties.includes(id))
            .filter((id) => !standplaatsIdentificaties.includes(id));

        return forkJoin([
            this.queryBagGegevens("verblijfsobject", pandIdentificaties),
            this.queryBagGegevens("ligplaats", ligplaatsIdentificaties),
            this.queryBagGegevens("standplaats", standplaatsIdentificaties),
        ]).pipe(map(([vbo, stand, lig]) => [...vbo, ...stand, ...lig]));
    }

    private queryBagGegevens(type: string, identificaties: string[]): Observable<BagGegevens[]> {
        if (identificaties.length > 0) {
            const body = this.getTypeIdsQuery(type, identificaties);

            return this.httpClient.post<GeoJSONFeatureCollection>(this.endpointsService.bagService, body).pipe(
                map((result) => {
                    if (!result.features || result.features.length === 0) {
                        throw new Error("invalid response");
                    }
                    const formatGeoJSON = new GeoJSON();
                    return result.features.map((feature) => {
                        const gegevens: BagGegevens = feature.properties as BagGegevens;
                        const features: Feature<Geometry>[] = formatGeoJSON.readFeatures(feature);
                        const center_geo = type === "verblijfsobject" ? features : [];
                        const geometry = ["ligplaats", "standplaats"].includes(type) ? features : [];
                        return { ...gegevens, center_geo, geometry };
                    });
                })
            );
        }
        return of([]);
    }

    private getTypeIdsQuery(type: string, identificaties: string[]) {
        return `<wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" service="WFS" version="1.1.0" xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.0.0/WFS-transaction.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" outputFormat="application/json">
      <wfs:Query typeName="${type}" xmlns:null="http://bag.geonovum.nl">
        <ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
          ${this.makeIdQueries(identificaties)}
        </ogc:Filter>
      </wfs:Query>
    </wfs:GetFeature>
    `;
    }

    getPandGegevens(adresseerbaarObjectId: string): Observable<BagGegevens> {
        return this.getAllPandGegevens([adresseerbaarObjectId]).pipe(
            map((gegevens) => (gegevens ? gegevens[0] : null))
        );
    }

    getLigplaatsGegevens(identificatie: string): Observable<BagGegevens[]> {
        return this.getBagGegevens(identificatie, "ligplaats");
    }

    getStandplaatsGegevens(identificatie: string): Observable<BagGegevens[]> {
        return this.getBagGegevens(identificatie, "standplaats");
    }

    getBagGegevens(identificatie: string, type: "ligplaats" | "standplaats"): Observable<BagGegevens[]> {
        const query = `<wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" service="WFS" version="1.1.0" xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.0.0/WFS-transaction.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" outputFormat="application/json">
                    <wfs:Query typeName="${type}" xmlns:null="http://bag.geonovum.nl">
                      <ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
                        <ogc:PropertyIsEqualTo>
                          <ogc:PropertyName>identificatie</ogc:PropertyName>
                          <ogc:Literal>${identificatie}</ogc:Literal>
                        </ogc:PropertyIsEqualTo>
                      </ogc:Filter>
                    </wfs:Query>
                  </wfs:GetFeature>`;
        return this.httpClient.post<GeoJSONFeatureCollection>(this.endpointsService.bagService, query).pipe(
            map((result) => {
                if (!result.features || result.features.length !== 1) {
                    return [];
                }
                const bagResult: BagGegevens = result.features[0].properties as BagGegevens;
                const geoJSON: GeoJSON = new GeoJSON();
                const geometry: Feature<Geometry>[] = geoJSON.readFeatures(result);
                return [
                    {
                        ...bagResult,
                        geometry,
                    },
                ];
            })
        );
    }

    getVerblijfsobjectenByPandId(pandidentificatie: string[]): Observable<BagGegevens[]> {
        const body = `<wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" service="WFS" version="1.1.0" xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.0.0/WFS-transaction.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" outputFormat="application/json">
                    <wfs:Query typeName="verblijfsobject" xmlns:null="http://bag.geonovum.nl">
                      <ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
                        ${this.makeIdQueries(pandidentificatie, "pandidentificatie")}
                      </ogc:Filter>
                    </wfs:Query>
                  </wfs:GetFeature>`;
        return this.httpClient.post<GeoJSONFeatureCollection>(this.endpointsService.bagService, body).pipe(
            map((result) => {
                return result.features.map((feature) => feature.properties as BagGegevens);
            })
        );
    }

    getVerblijfsobjectenByPandIds(pandidentificaties: string[]): Observable<VerblijfsObject[]> {
        if (pandidentificaties.length === 1) {
            return this.getVerblijfsobjectenByPandId(pandidentificaties);
        }
        return from(chunkIdentifiers(pandidentificaties, environment.pdokOrLimit)).pipe(
            mergeMap((pands) => this.vbosByPandIds(pands), 2),
            toArray(),
            map((vbos) => vbos.reduce((arr, vals) => [...arr, ...vals], [])),
            catchError((err) => {
                console.log(err);
                return of([]);
            })
        );
    }

    private vbosByPandIds(pandids: string[]): Observable<VerblijfsObject[]> {
        const pandTerms: string[] = pandids.map(
            (p) => `<ogc:PropertyIsEqualTo>
            <ogc:PropertyName>pandidentificatie</ogc:PropertyName>
            <ogc:Literal>${p}</ogc:Literal>
          </ogc:PropertyIsEqualTo>`
        );

        const filterBody: string =
            pandTerms.length > 1
                ? `<ogc:Or>
                ${pandTerms}
              </ogc:Or>`
                : `${pandTerms}`;

        const body = `<wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" service="WFS" version="1.1.0" xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.0.0/WFS-transaction.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" outputFormat="application/json">
                    <wfs:Query typeName="verblijfsobject" xmlns:null="http://bag.geonovum.nl">
                      <ogc:PropertyName xmlns:ogc="http://www.opengis.net/ogc">identificatie</ogc:PropertyName>
                      <ogc:PropertyName xmlns:ogc="http://www.opengis.net/ogc">openbare_ruimte</ogc:PropertyName>
                      <ogc:PropertyName xmlns:ogc="http://www.opengis.net/ogc">huisnummer</ogc:PropertyName>
                      <ogc:PropertyName xmlns:ogc="http://www.opengis.net/ogc">huisletter</ogc:PropertyName>
                      <ogc:PropertyName xmlns:ogc="http://www.opengis.net/ogc">woonplaats</ogc:PropertyName>
                      <ogc:PropertyName xmlns:ogc="http://www.opengis.net/ogc">postcode</ogc:PropertyName>
                      <ogc:PropertyName xmlns:ogc="http://www.opengis.net/ogc">toevoeging</ogc:PropertyName>
                      <ogc:PropertyName xmlns:ogc="http://www.opengis.net/ogc">pandidentificatie</ogc:PropertyName>
                      <ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
                        ${filterBody}
                      </ogc:Filter>
                    </wfs:Query>
                  </wfs:GetFeature>`;
        return this.httpClient.post<GeoJSONFeatureCollection>(this.endpointsService.bagService, body).pipe(
            map((result: GeoJSONFeatureCollection) => {
                if (!result.features) {
                    return [];
                }
                return result.features.map((feature) => feature.properties as VerblijfsObject);
            })
        );
    }

    filter(extent: Extent, bouwjaar?: Range, oppervlakte?: Range): Observable<Feature<Geometry>[]> {
        const filters = [
            `<ogc:BBOX>
              <ogc:PropertyName>geometrie</ogc:PropertyName>
              <gml:Envelope xmlns:gml="http://www.opengis.net/gml">
                  <gml:lowerCorner>${extent[0]} ${extent[1]}</gml:lowerCorner>
                  <gml:upperCorner>${extent[2]} ${extent[3]}</gml:upperCorner>
              </gml:Envelope>
          </ogc:BBOX>`,
        ];
        if (bouwjaar?.min) {
            filters.push(`
            <ogc:PropertyIsGreaterThanOrEqualTo>
                <ogc:PropertyName>bouwjaar</ogc:PropertyName>
                <ogc:Literal>${bouwjaar.min}</ogc:Literal>
            </ogc:PropertyIsGreaterThanOrEqualTo>`);
        }
        if (bouwjaar?.max) {
            filters.push(`
            <ogc:PropertyIsLessThanOrEqualTo>
                <ogc:PropertyName>bouwjaar</ogc:PropertyName>
                <ogc:Literal>${bouwjaar.max}</ogc:Literal>
            </ogc:PropertyIsLessThanOrEqualTo>`);
        }

        if (oppervlakte?.min) {
            filters.push(`
            <ogc:PropertyIsGreaterThanOrEqualTo>
                <ogc:PropertyName>oppervlakte_min</ogc:PropertyName>
                <ogc:Literal>${oppervlakte.min}</ogc:Literal>
            </ogc:PropertyIsGreaterThanOrEqualTo>`);
        }

        if (oppervlakte?.max) {
            filters.push(`
            <ogc:PropertyIsLessThanOrEqualTo>
                <ogc:PropertyName>oppervlakte_max</ogc:PropertyName>
                <ogc:Literal>${oppervlakte.max}</ogc:Literal>
            </ogc:PropertyIsLessThanOrEqualTo>`);
        }
        let filterstring = filters.join("");

        if (filters.length > 1) {
            filterstring = `<ogc:And>${filterstring}</ogc:And>`;
        }

        const body = `<wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" maxFeatures="1000" service="WFS" version="1.1.0"
                xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd"
                xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" outputFormat="application/json">
                    <wfs:Query typeName="pand" srsName="EPSG:28992" xmlns:ogc="http://www.opengis.net/ogc">
                        <ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
                            ${filterstring}
                        </ogc:Filter>
                    </wfs:Query>
                </wfs:GetFeature>`;
        return this.httpClient.post<GeoJSONFeatureCollection>(this.endpointsService.bagService, body).pipe(
            map((result: GeoJSONFeatureCollection) => {
                if (!result.features || result.features.length === 0) {
                    return [];
                }
                const formatGeoJSON = new GeoJSON();
                return formatGeoJSON.readFeatures(result);
            }),
            catchError(() => [])
        );
    }

    markPandenByAdresseerbaarObjectIds(adresseerbaarObjectIds: string[]): void {
        const ids: string[] = adresseerbaarObjectIds
            .filter((id) => id)
            .map((id) => this.prefixPipe.transform(id, 16, "0"));
        if (adresseerbaarObjectIds.length === 0) {
            return;
        }
        const query = `<wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" service="WFS" version="1.1.0" xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.0.0/WFS-transaction.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" outputFormat="application/json">
                    <wfs:Query typeName="verblijfsobject" xmlns:null="http://bag.geonovum.nl">
                      <ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
                        ${this.makeIdQueries(ids)}
                      </ogc:Filter>
                    </wfs:Query>
                  </wfs:GetFeature>`;
        this.httpClient
            .post<GeoJSONFeatureCollection>(this.endpointsService.bagService, query)
            .pipe(
                map((result) => {
                    if (!result.features || result.features.length === 0) {
                        return [];
                    }
                    return result.features
                        .map((feat) => feat.properties.pandidentificatie as string)
                        .filter((value, index, self) => self.indexOf(value) === index);
                }),
                switchMap((pandIds: string[]) => this.pandByPandIdFeatures(pandIds))
            )
            .subscribe((features) => {
                this.arceringService.addToBagFeatures(features);
            });
    }

    fetchPandGeometries(pand: BagGegevens, gekoppeldePandIds: string[]): Observable<Feature<Geometry>[]> {
        const pandIds: string[] = [pand?.pandidentificatie, ...gekoppeldePandIds].filter((pand) => pand);

        const features: Observable<Feature<Geometry>[]>[] = [];

        const pandType: string = pand?.identificatie?.slice(4);

        if (pandType && pand.geometry && (pandType.startsWith("02") || pandType.startsWith("03"))) {
            features.push(of(pand.geometry));
        }

        if (pandIds.length > 0) {
            features.push(this.pandByPandIdFeatures(pandIds));
        }

        return forkJoin(features).pipe(map((feats) => feats.reduce((acc, value) => [...acc, ...value], [])));
    }

    zoomToArcering(feats: Feature<Geometry>[], pand: BagGegevens, zoomTo: boolean): void {
        let feat = null;

        if (pand?.center_geo && pand.center_geo.length > 0) {
            feat = pand.center_geo[0];
        } else if (feats && feats.length > 0) {
            feat = feats[0];
        }
        if (feat && zoomTo) {
            const featExtent = feat.getGeometry().getExtent();
            const currentZoom = this.mapService.getMap(environment.mapName).getView().getZoom();
            this.mapService.getMap(environment.mapName).getView().fit(featExtent);
            this.mapService
                .getMap(environment.mapName)
                .getView()
                .setZoom(currentZoom < 13 ? 15 : currentZoom);
        }
    }

    pandByPandIdFeatures(pandIds: string[]): Observable<Feature<Geometry>[]> {
        if (pandIds.length > 0) {
            const filterQuery: string = this.makeIdQueries(pandIds);
            const query = `<wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" service="WFS" version="1.1.0" xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.0.0/WFS-transaction.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" outputFormat="application/json">
      <wfs:Query typeName="pand" xmlns:null="http://bag.geonovum.nl">
        <ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
          ${filterQuery}
        </ogc:Filter>
      </wfs:Query>
    </wfs:GetFeature>`;
            return this.httpClient.post<GeoJSONFeatureCollection>(this.endpointsService.bagService, query).pipe(
                map((result) => {
                    if (!result.features || result.features.length === 0) {
                        return [];
                    }
                    const formatGeoJSON = new GeoJSON();
                    return formatGeoJSON.readFeatures(result);
                }),
                catchError(() => [])
            );
        }
        return of([] as Feature<Geometry>[]);
    }

    private makeIdQueries(pandIds: string[], type = "identificatie") {
        if (pandIds.length === 1) {
            return this.makeIdQuery(pandIds[0], type);
        } else {
            const queries = pandIds.map((id) => this.makeIdQuery(id, type)).join("");
            return `<ogc:Or>
                ${queries}
            </ogc:Or>`;
        }
    }

    private makeIdQuery(pandId: string, type: string) {
        const prefixId: string = this.prefixPipe.transform(pandId, 16, "0");
        return `<ogc:PropertyIsEqualTo>
              <ogc:PropertyName>${type}</ogc:PropertyName>
              <ogc:Literal>${prefixId}</ogc:Literal>
            </ogc:PropertyIsEqualTo>`;
    }
}
