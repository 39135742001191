<ng-container *ngIf="suggestions.length > 0">
    <ul class="resultaat-suggesties__suggestions">
        <li *ngFor="let suggestion of suggestions; let idx = index">
            <button
                type="button"
                class="btn btn-link"
                [id]="identifier + '-' + idx"
                (click)="onLookup(suggestion, idx)"
            >
                <span class="btn-text">{{ suggestion.weergavenaam }}</span>
            </button>
        </li>
    </ul>
    <div class="resultaat-suggesties__footer" *ngIf="hasMoreSuggestions">
        <button
            type="button"
            class="btn btn-link"
            [id]="identifier + '-suggest-show-all'"
            *ngIf="!showAll"
            (click)="showAll = true"
        >
            <span class="btn-text">> Alles weergeven</span>
        </button>
        <button
            type="button"
            class="btn btn-link"
            [id]="identifier + '-suggest-show-less'"
            *ngIf="showAll"
            (click)="showAll = false"
        >
            <span class="btn-text">> Minder weergeven</span>
        </button>
    </div>
</ng-container>
