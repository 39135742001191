import { Pipe, PipeTransform } from "@angular/core";
import { Adres } from "../../model/dto/Adres";

@Pipe({
    name: "streetAddress",
})
export class StreetAddressPipe implements PipeTransform {
    transform(value: Adres): string {
        let nummer = "";

        if (value.huisnummer) {
            nummer += value.huisnummer;
        }

        if (value.huisletter) {
            nummer += value.huisletter;
        }

        if (value.huisnummertoevoeging) {
            nummer += `-${value.huisnummertoevoeging}`;
        }

        return [value.straatnaam, nummer, value.locatieomschrijving].filter((value) => value).join(" ");
    }
}
