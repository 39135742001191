<app-modal (close)="onClose()" title="Veelgestelde vragen">
    <h3>Er is geen actuele WOZ-waarde bekend van mijn woning; hoe kan ik deze opvragen?</h3>
    <p>
        Het WOZ-waardeloket toont de gegevens die uw gemeente heeft aangeleverd. In 2022 toont het WOZ-waardeloket de
        WOZ-waarden zoals die begin 2022 zijn vastgesteld. Hierbij gaat de gemeente uit van de waardepeildatum 1 januari
        van het voorgaande jaar. In 2022 is de meest recente WOZ-waarde dus de waarde met (waarde) peildatum 1 januari
        2021 (01-01-2021). Bij vragen over deze WOZ-waarde kunt u contact opnemen met uw gemeente (of het
        samenwerkingsverband dat voor uw gemeente de WOZ-taxaties verzorgt).
    </p>

    <h3>Ik zoek een WOZ-waarde van vóór 2014. Waar kan ik deze vinden?</h3>
    <p>
        Op wozwaardeloket.nl kunt u alleen de WOZ-waarde vanaf waardepeildatum 2014 opvragen. Oudere WOZ-waarden kunt u
        eventueel bij uw gemeente opvragen.
    </p>

    <h3>Hoe wordt de WOZ-waarde bepaald?</h3>
    <p>
        Uw gemeente bepaalt de WOZ-waarde. Kijk voor meer informatie op de website van uw gemeente of op
        <a href="https://www.waarderingskamer.nl" target="_blank">
            <span>www.waarderingskamer.nl<fa-icon [icon]="faExternalLink"></fa-icon> </span>
        </a>
    </p>

    <h3>Hoe kan ik een fout melden?</h3>
    <p>U kunt fouten melden op diverse manieren, afhankelijk van de fout.</p>
    <p>
        Fouten in de WOZ-waarde: Neem contact op met uw gemeente of het samenwerkingsverband dat voor uw gemeente de
        WOZ-taxaties verzorgt.
    </p>
    <p>
        Fouten in bouwjaar, gebruiksdoel en oppervlakte kunt u melden bij de Basisregistratie Adressen en Gebouwen (BAG)
        via
        <a href="https://bagviewer.kadaster.nl" target="_blank">
            <span> https://bagviewer.kadaster.nl <fa-icon [icon]="faExternalLink"></fa-icon> </span> </a
        >. Onder "Terugmelding" kunt u lezen hoe u een fout kunt melden.
    </p>
    <p>
        Fouten in achtergrondkaart en omgeving kunt u melden op
        <a href="https://verbeterdekaart.nl" target="_blank">
            <span>
                www.verbeterdekaart.nl
                <fa-icon [icon]="faExternalLink"></fa-icon>
            </span> </a
        >.
    </p>

    <h3>Wat moet ik doen als ik denk dat de WOZ-waarde onjuist is?</h3>
    <p>
        Als u denkt dat de WOZ-waarde van de woning waarvan u eigenaar bent of huurder onjuist is, kunt u contact
        opnemen met uw gemeente of het samenwerkingsverband dat voor uw gemeente de WOZ-taxaties verzorgt. Zij kunnen u
        een nadere toelichting geven en eventueel een gemaakte fout herstellen. Als u ook dan nog van oordeel bent dat
        de WOZ-waarde onjuist is, kunt u bezwaar maken tegen de WOZ-waarde. Alleen de eigenaar of de huurder van een
        woning kan bezwaar maken..
    </p>
    <p>
        U maakt bezwaar door een bezwaarschrift in te dienen. Op de website van uw gemeente (of het samenwerkingsverband
        dat voor uw gemeente de WOZ-taxaties verzorgt) of op uw aanslagbiljet kunt u lezen hoe u een bezwaar indient.
    </p>
    <p>U kunt geen bezwaar indienen bij het Kadaster of via het WOZ-waardeloket.</p>
</app-modal>
