import { Component, Input } from "@angular/core";

@Component({
    selector: "app-dataset-toggle",
    templateUrl: "./dataset-toggle.component.html",
    styleUrls: ["./dataset-toggle.component.scss"],
})
export class DatasetToggleComponent {
    src = "./assets/img/kaart.png";

    @Input()
    expanded: boolean;

    get mode(): "kaart" | "luchtfoto" | undefined {
        return this._mode;
    }

    @Input()
    set mode(value: "kaart" | "luchtfoto" | undefined) {
        if (!value) {
            return;
        }
        this._mode = value;
        this.src = this._mode === "kaart" ? "./assets/img/kaart.png" : "./assets/img/luchtfoto.png";
    }

    private _mode: "kaart" | "luchtfoto" | undefined = "kaart";
}
