import { DatePipe } from "@angular/common";
import { Feature } from "ol";
import { Geometry, SimpleGeometry } from "ol/geom";
import { PrefixPipe } from "../../shared/utils/prefix.pipe";
import { StreetAddressPipe } from "../../shared/utils/street-address.pipe";
import { Adres } from "../dto/Adres";
import { BagGegevens } from "../dto/BagGegevens";
import { Waarde } from "../dto/Waarde";
import { WozObject } from "../dto/WozObject";
import { Attributes } from "../print/Attributes";
import { Feature as PrintFeature } from "../print/Feature";

export class PrintMapper {
    private static DatePipe = new DatePipe("nl-NL");

    static toPrintAddress(adres: Adres): Partial<Attributes> {
        return {
            adres: new StreetAddressPipe().transform(adres),
            postcode: adres.postcode || "",
            woonplaats: adres.woonplaatsnaam,
        };
    }

    static toPrintFeature(feature: Feature<Geometry>, type: string): PrintFeature {
        const geom = feature.getGeometry() as SimpleGeometry;

        return {
            type: "Feature",
            properties: {
                type,
            },
            geometry: {
                type: feature.getGeometry().getType().toString(),
                coordinates: [geom.getCoordinates() as [number, number]],
            },
        };
    }

    static toPrintKenmerken(pandgegevens?: BagGegevens, wozObject?: WozObject): Partial<Attributes> {
        return {
            oppervlakte: pandgegevens?.oppervlakte,
            bouwjaar: parseInt(pandgegevens?.bouwjaar, 10),
            gebruiksdoel: pandgegevens?.gebruiksdoel,
            wozIdentificatie: wozObject?.wozobjectnummer
                ? new PrefixPipe().transform(wozObject.wozobjectnummer, 12, "0")
                : undefined,
            grondoppervlakte: wozObject?.grondoppervlakte,
        };
    }

    static toPrintWozwaarden(wozWaarden?: Waarde[]): Partial<Attributes> {
        if (!wozWaarden) {
            return { datasource: [] };
        }
        return {
            datasource: [
                {
                    table: {
                        data: wozWaarden.map((wozWaarde) => [
                            PrintMapper.DatePipe.transform(wozWaarde.peildatum, "shortDate"),
                            wozWaarde.vastgesteldeWaarde,
                        ]),
                        columns: ["peildatum", "wozwaarde"],
                    },
                },
            ],
        };
    }
}
