import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { MapComponentEvent, WmtsLayerOptions } from "@kadaster/generieke-geo-componenten-map";
import { Feature } from "ol";
import { environment } from "../../environments/environment";
import { BagService } from "../service/bag.service";
import { ArceringService } from "../service/arcering.service";
import { BagVbosEvent } from "../model/dto/BagVbosEvent";
import { BagGegevens } from "../model/dto/BagGegevens";
import { LayerService } from "../service/layer.service";
import { BagKadastraalObject } from "../model/dto/BagKadastraalObject";
import { UiService } from "../service/ui.service";
import {
    Dataset,
    DatasetSwitcherButton,
    DatasetSwitcherEvent,
    Layer,
    Theme,
} from "@kadaster/generieke-geo-componenten-dataset-tree";
import { Geometry } from "ol/geom";

interface LayerSelector {
    visible: boolean;
    theme: Theme;
}

@Component({
    selector: "app-dataset",
    templateUrl: "./dataset.component.html",
    styleUrls: ["./dataset.component.scss"],
})
export class DatasetComponent implements OnInit {
    backgroundTheme: Theme;
    datasetSwitcherButtons: DatasetSwitcherButton[] = [];
    wmsDataSets: Dataset[] = [];
    wmtsDataSets: Dataset[] = [];
    layers: LayerSelector[] = [];
    mapName = environment.mapName;
    wozThemes: Theme[];
    @HostBinding("class.is-open")
    @Input()
    open = true;
    @Output() searchByPand = new EventEmitter<BagGegevens>();
    @Output() searchByVBOs: EventEmitter<BagVbosEvent> = new EventEmitter();
    @Output() searchByKOZ: EventEmitter<BagKadastraalObject> = new EventEmitter();
    @Output() close = new EventEmitter();

    constructor(
        private arceringService: ArceringService,
        private bagService: BagService,
        private layerService: LayerService,
        private ui: UiService
    ) {}

    ngOnInit(): void {
        this.layerService.getLayers().subscribe((data) => {
            this.wozThemes = data;

            // First 2 layers are the background layers
            this.layers = this.wozThemes.slice(2).map((x) => ({ visible: true, theme: x }));

            const kaartButton = new DatasetSwitcherButton(this.wozThemes[0].themeName, "./assets/img/kaart.png");
            const luchtfotoButton = new DatasetSwitcherButton(
                this.wozThemes[1].themeName,
                "./assets/img/luchtfoto.png"
            );

            this.datasetSwitcherButtons.push(kaartButton, luchtfotoButton);
            this.datasetSwitcherEvent(new DatasetSwitcherEvent("initieel", data[0]));
        });
    }

    onClose(): void {
        this.close.emit();
    }

    getWmtsOptions(url: string, layer: Layer): WmtsLayerOptions {    
        return {
            url: url,
            layer: layer.technicalName,
            minResolution: layer.minResolution,
            maxResolution: layer.maxResolution,
            zIndex: layer.zIndex,
            getFeatureInfoOnSingleclick: layer.getFeatureInfoOnSingleclick,
            attributions: layer.attributions,
            mapName: this.mapName,
            layerName: layer.name,
        };
    }

    datasetSwitcherEvent(event: DatasetSwitcherEvent): void {
        this.backgroundTheme = event.value;
        this.ui.setBackground(event.value.themeName);
        this.setDatasets();
    }

    layerChange(): void {
        this.setDatasets();
    }

    private setDatasets() {
        const layers = this.layers.filter((l) => l.visible).map((l) => l.theme.datasets);
        const datasets = [].concat(...this.backgroundTheme.datasets, ...layers);

        this.wmtsDataSets = datasets.filter((dataset) => dataset.services[0].type === "wmts");
        this.wmsDataSets = datasets.filter((dataset) => dataset.services[0].type === "wms");
    }

    onEvent([pand, ligplaats, standplaats, perceel]: MapComponentEvent[]): void {
        if (pand && pand.value[0].getProperties().aantal_verblijfsobjecten) {
            this.extractPand(
                pand.value.map((pand) => pand.getProperties() as BagGegevens),
                pand.value
            );
        } else if (pand) {
            this.bagService
                .getLigplaatsGegevens(pand.value[0].getProperties().identificatie)
                .subscribe((bagResults) => this.searchByVBOs.emit({ bagResults, geoFeatures: pand.value }));
        } else if (ligplaats) {
            this.bagService
                .getLigplaatsGegevens(ligplaats.value[0].getProperties().identificatie)
                .subscribe((bagResults) => this.searchByVBOs.emit({ bagResults, geoFeatures: ligplaats.value }));
        } else if (standplaats) {
            this.bagService
                .getStandplaatsGegevens(standplaats.value[0].getProperties().identificatie)
                .subscribe((bagResults) => this.searchByVBOs.emit({ bagResults, geoFeatures: standplaats.value }));
        } else if (perceel) {
            this.searchByKOZ.emit({
                ...perceel.value[0].getProperties(),
                geometry: perceel.value,
            });
        }
    }

    private extractPand(properties: BagGegevens[], features: Feature<Geometry>[]) {
        if (properties.length === 1 && properties[0].aantal_verblijfsobjecten === "0") {
            this.searchByPand.emit({
                ...properties[0],
                geometry: features,
            });
        } else {
            this.bagService
                .getVerblijfsobjectenByPandId(properties.map((property) => property.identificatie))
                .subscribe((vbos) =>
                    this.searchByVBOs.emit({
                        bagResults: vbos,
                        geoFeatures: features,
                    })
                );
        }
    }
}
