<div class="sidebar" [ngClass]="{ 'sidebar--empty': (sidebarOpen | async) === false }">
    <div class="sidebar__background"></div>
    <div class="sidebar__content">
        <div class="sidebar__inner-content">
            <app-sidebar-block
                (toggle)="onToggle('filters', $event)"
                [open]="(openedBlocks | async).filters"
                [closeable]="false"
                identifier="filter-input"
                title="Filteren op de kaart"
                toggleTitle="Filter"
            >
                <app-filter [zoomLevel]="zoomLevel" (filterResults)="onFilter($event)"> </app-filter>
            </app-sidebar-block>
            <app-sidebar-loader *ngIf="searching" title="Resultaten"></app-sidebar-loader>
            <app-sidebar-block
                *ngIf="suggestions?.length > 1"
                [open]="(openedBlocks | async).suggestions"
                identifier="resultaten"
                title="Resultaten ({{ suggestions?.length }})"
                toggleTitle="Resultaten"
                (toggle)="onToggle('suggestions', $event)"
                (close)="onCloseSuggestions()"
            >
                <app-resultaat-suggesties
                    identifier="resultaat"
                    [suggestions]="suggestions"
                    (close)="onCloseSuggestions()"
                    (lookup)="onLookup($event); resultFromSuggestions = true"
                    (indexSuggestion)="onIndex($event)"
                >
                </app-resultaat-suggesties>
            </app-sidebar-block>

            <app-sidebar-block
                *ngIf="resultaat"
                [open]="(openedBlocks | async).result"
                title="Geselecteerd resultaat"
                toggleTitle="Geselecteerd resultaat"
                identifier="resultaat"
                (toggle)="onToggle('result', $event)"
                (close)="onCloseResult()"
            >
                <app-result-nav
                    *ngIf="resultFromSuggestions"
                    [suggestions]="suggestions"
                    [(indexSuggestion)]="indexSuggestion"
                    (lookup)="onLookup($event)"
                ></app-result-nav>
                <app-result [resultaat]="resultaat"></app-result>
            </app-sidebar-block>
        </div>
    </div>
</div>
<div class="sidebar-sibling">
    <ng-content></ng-content>
</div>
