import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MapService } from "@kadaster/generieke-geo-componenten-map";
import { Feature } from "ol";
import { Extent } from "ol/extent";
import { Geometry } from "ol/geom";
import { of } from "rxjs";
import { catchError, defaultIfEmpty, finalize, map, switchMap, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AdresseerbaarObjectSuggestion } from "../../model/dto/AdresseerbaarObjectSuggestion";
import { VerblijfsObject } from "../../model/dto/VerblijfsObject";
import { Zoom } from "../../model/dto/Zoom";
import { ArceringService } from "../../service/arcering.service";
import { BagService } from "../../service/bag.service";
import { WozService } from "../../service/woz.service";
import { Range } from "../filter-input/filter-input.component";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-filter",
    templateUrl: "./filter.component.html",
    styleUrls: ["./filter.component.scss"],
})
export class FilterComponent {
    @Input()
    set zoomLevel(value: Zoom) {
        this._zoomLevel = value;
        this.canFilter = value?.zoom >= environment.filterMaxZoom;
        if (this.filterActive) {
            this.applyButtonLabel = "Opnieuw toepassen";

            if (!this.canFilter) {
                this.resetFilters();
            }
        }
    }

    applyButtonLabel = "Filter toepassen";
    bouwjaar: Range;
    canFilter = false;
    filterActive = false;
    oppervlakte: Range;
    wozWaarde: Range;
    hasSuggestions: boolean;
    loadingResults = false;
    loadingIcon = faSpinner;

    @Output()
    filterResults = new EventEmitter<AdresseerbaarObjectSuggestion[]>();

    private _zoomLevel: Zoom;

    constructor(
        private arceringService: ArceringService,
        private bagService: BagService,
        private mapService: MapService,
        private wozService: WozService
    ) {}

    applyFilters(): void {
        // Reset models so min/max is corrected
        if (this.bouwjaar) {
            this.bouwjaar = { ...this.bouwjaar };
        }
        if (this.oppervlakte) {
            this.oppervlakte = { ...this.oppervlakte };
        }
        if (this.wozWaarde) {
            this.wozWaarde = { ...this.wozWaarde };
        }

        this.loadingResults = true;

        const extent: Extent = this.mapService.getMap(environment.mapName).getView().calculateExtent();

        this.arceringService.clearLayer("filter");

        this.bagService
            .filter(extent, this.bouwjaar, this.oppervlakte)
            .pipe(
                switchMap((features: Feature<Geometry>[]) =>
                    this.bagService
                        .getVerblijfsobjectenByPandIds(features.map((f) => f.getProperties().identificatie))
                        .pipe(map((bag) => [bag, features]))
                ),
                switchMap(([vbos, pand]: [VerblijfsObject[], Feature<Geometry>[]]) => {
                    return this.wozService
                        .waardeByVbo(
                            vbos.map((vbo) => vbo.identificatie),
                            this.wozWaarde ? this.wozWaarde.min : 1,
                            this.wozWaarde ? this.wozWaarde.max : null
                        )
                        .pipe(
                            map((value) => {
                                const ids = value.map((id) => parseInt(id, 10));
                                const filteredVbos = vbos.filter((vbo) =>
                                    ids.includes(parseInt(vbo.identificatie, 10))
                                );
                                return [filteredVbos, pand];
                            })
                        );
                }),
                tap(([vbos, panden]: [VerblijfsObject[], Feature<Geometry>[]]) => {
                    if (vbos.length > 0) {
                        const filteredPanden = panden.filter((pand) =>
                            vbos.find((vbo) => vbo.pandidentificatie === pand.getProperties().identificatie)
                        );
                        this.arceringService.addFeaturesToLayer(filteredPanden, "filter");
                    }
                }),
                switchMap(([vbos]) => this.wozService.searchByVerblijfsObjecten(vbos)),
                defaultIfEmpty([]),
                catchError(() => {
                    return of([]);
                }),
                finalize(() => (this.loadingResults = false))
            )
            .subscribe((suggestions) => {
                this.hasSuggestions = suggestions.length > 0;
                this.filterActive = true;
                this.filterResults.emit(suggestions);
            });
    }

    resetFilters(): void {
        this.applyButtonLabel = "Filter toepassen";
        this.bouwjaar = undefined;
        this.oppervlakte = undefined;
        this.wozWaarde = undefined;
        this.filterActive = false;
        this.arceringService.clearLayer("filter");
        this.filterResults.emit(undefined);
    }
}
