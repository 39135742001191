import { AdresseerbaarObjectSuggestion } from "../dto/AdresseerbaarObjectSuggestion";
import { AdresMapper } from "./AdresMapper";
import { StreetAddressPipe } from "../../shared/utils/street-address.pipe";
import { WozObject } from "../dto/WozObject";
import { VerblijfsObject } from "../dto/VerblijfsObject";

export class AdresseerbaarObjectSuggestionMapper {
    static streetAddressPipe: StreetAddressPipe = new StreetAddressPipe();

    static fromWozObject(wozObject: WozObject, source: string): AdresseerbaarObjectSuggestion {
        const weergavenaam = this.streetAddressPipe.transform(AdresMapper.fromWozObject(wozObject));
        return {
            id: wozObject.wozobjectnummer?.toString(10),
            weergavenaam: `${weergavenaam}, ${wozObject.postcode || ""} ${wozObject.woonplaatsnaam || ""}`,
            type: wozObject.locatieomschrijving,
            score: 1,
            source,
            adresseerbaarObjectId: wozObject.adresseerbaarobjectid,
            huisnummer: wozObject.huisnummer?.toString(10),
            toevoeging: wozObject.huisnummertoevoeging,
            openbareruimte: wozObject.openbareruimtenaam,
            woonplaats: wozObject.woonplaatsnaam,
            huisletter: wozObject.huisletter,
            verbondenAdresseerbareObjecten: wozObject.verbondenAdresseerbareObjecten,
            ontleendeAdresseerbareObjecten: wozObject.ontleendeAdresseerbareObjecten,
        };
    }

    static fromVerblijfsObject(verblijfsObject: VerblijfsObject, source: string): AdresseerbaarObjectSuggestion {
        const weergavenaam = this.streetAddressPipe.transform(AdresMapper.fromVerblijfsObject(verblijfsObject));
        return {
            id: undefined,
            weergavenaam: `${weergavenaam}, ${verblijfsObject.postcode || ""} ${verblijfsObject.woonplaats || ""}`,
            type: "",
            score: 1,
            source,
            adresseerbaarObjectId: parseInt(verblijfsObject.identificatie, 10),
            huisnummer: verblijfsObject.huisnummer,
            toevoeging: verblijfsObject.toevoeging,
            openbareruimte: verblijfsObject.openbare_ruimte,
            woonplaats: verblijfsObject.woonplaats,
            huisletter: verblijfsObject.huisletter,
            verbondenAdresseerbareObjecten: [],
            ontleendeAdresseerbareObjecten: [],
        };
    }
}
