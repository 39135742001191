import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TermsInfoComponent } from "./terms-info/terms-info.component";
import { AboutInfoComponent } from "./about-info/about-info.component";
import { SharedModule } from "../shared/shared.module";
import { InfoModalComponent } from "./info-modal/info-modal.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { VoorschermComponent } from "./voorscherm/voorscherm.component";
import { VoorschermModalComponent } from "./voorscherm-modal/voorscherm-modal.component";
import { VeelgesteldeVragenComponent } from "./veelgestelde-vragen/veelgestelde-vragen.component";
import { WerkingWozwaardeloketComponent } from "./werking-wozwaardeloket/werking-wozwaardeloket.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UitlegGegevensComponent } from "./uitleg-gegevens/uitleg-gegevens.component";
import { RouterModule } from "@angular/router";
import { ToegankelijkheidsVerklaringComponent } from "./toegankelijkheids-verklaring/toegankelijkheids-verklaring.component";

@NgModule({
    declarations: [
        TermsInfoComponent,
        AboutInfoComponent,
        InfoModalComponent,
        VoorschermComponent,
        VoorschermModalComponent,
        VeelgesteldeVragenComponent,
        WerkingWozwaardeloketComponent,
        UitlegGegevensComponent,
        ToegankelijkheidsVerklaringComponent,
    ],
    exports: [InfoModalComponent, VoorschermModalComponent],
    imports: [CommonModule, SharedModule, OverlayModule, FontAwesomeModule, RouterModule],
})
export class InfoModule {}
