import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { FilterInputComponent } from "./filter-input/filter-input.component";

import { FilterComponent } from "./filter/filter.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
    declarations: [FilterComponent, FilterInputComponent],
    imports: [CommonModule, SharedModule, FormsModule, FontAwesomeModule],
    exports: [FilterComponent, FilterInputComponent],
})
export class FilterModule {}
