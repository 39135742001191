import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: "app-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements AfterViewInit {
    @Input()
    title: string;

    @Output()
    close: EventEmitter<void> = new EventEmitter();

    @ViewChild("closebutton")
    closeButton: ElementRef;

    ngAfterViewInit(): void {
        if (this.closeButton) {
            this.closeButton.nativeElement.focus();
        }
    }

    onClose(): void {
        this.close.next();
    }
}
