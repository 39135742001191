<app-modal (close)="onClose()" title="Bepalen WOZ-waarde">
    <p>
        De Wet Waardering Onroerende Zaken (Wet WOZ) regelt hoe de waarden van alle woningen en andere objecten moeten
        worden bepaald. Voor het taxeren van de WOZ-waarde vergelijkt de gemeente woningen met andere woningen die
        recent zijn verkocht. Dit noemen we een systematische vergelijking met woningen waarvan een verkoopprijs
        beschikbaar is.
    </p>
    <h3>Taxatiemodellen</h3>
    <p>
        We spreken over een 'systematische vergelijking', omdat de gemeente met een computermodel een groot aantal
        woningen tegelijk vergelijkt (en taxeert). Om dit taxatiemodel te maken en te gebruiken moet een gemeente twee
        dingen doen:
    </p>
    <ul>
        <li>De verkoopprijzen van woningen analyseren.</li>
        <li>De geregistreerde kenmerken van de woningen actueel houden.</li>
    </ul>
    <p>Dit doet de gemeente niet één keer per jaar, maar het hele jaar door.</p>
    <h3>Eisen aan taxaties woningen</h3>
    <p>
        Om te zorgen dat alle ruim acht miljoen woningen correct worden getaxeerd, geldt er een aantal kwaliteitseisen.
        De Waarderingskamer houdt toezicht op de taxaties van gemeenten om te zorgen dat alle gemeenten deze eisen
        nakomen.
    </p>
    <h3>Controle</h3>
    <p>
        De met het computermodel getaxeerde WOZ-waarden worden uitgebreid gecontroleerd en eventueel verbeterd. Voor
        sommige woningen is het moeilijk op basis van de beschikbare gegevens tot een goede taxatie te komen. In zulke
        gevallen kan de taxateur de woning ter plaatse bekijken voor aanvullende informatie. Wanneer de taxaties klaar
        zijn, controleert de gemeente de resultaten onder andere met behulp van beoordelingsprotocollen. De
        Waarderingskamer heeft deze protocollen opgesteld.
    </p>
    <h3>Denkt u dat de WOZ-waarde onjuist is?</h3>
    <p>
        Als u denkt dat de WOZ-waarde van uw woning niet juist is, dan kunt u contact opnemen met uw gemeente. Soms
        heeft een gemeente ervoor gekozen de WOZ-taxatie te laten uitvoeren door een samenwerkingsverband van gemeenten.
    </p>
    <p>
        Wanneer u de eigenaar of gebruiker (huurder) bent van de woning kunt u contact opnemen met de gemeente of het
        samenwerkingsverband.
    </p>
    <p>
        U kunt ook een bezwaarschrift indienen. Op de website van de gemeente of op uw aanslagbiljet kunt u lezen hoe u
        een bezwaar indient.
    </p>
    <p>U kunt geen bezwaar indienen bij het Kadaster of via het WOZ-waardeloket.</p>
    <h3>Meer weten?</h3>

    <p>
        Wilt u meer weten over de uitvoering van de Wet WOZ, kijk dan op de site van de Waarderingskamer (
        <a href="https://www.waarderingskamer.nl" target="_blank">
            <span> www.waarderingskamer.nl <fa-icon [icon]="faExternalLink"></fa-icon> </span>
        </a>
        ). De Waarderingskamer is een zelfstandig bestuursorgaan dat toezicht houdt op de uitvoering van de taxaties,
        die in het kader van de Wet WOZ door de gemeente wordt verricht.
    </p>
</app-modal>
