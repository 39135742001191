import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { take, tap } from "rxjs/operators";
import { Observable, timer } from "rxjs";
import { UiService } from "../service/ui.service";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    @Output()
    hamburger: EventEmitter<boolean> = new EventEmitter<boolean>();

    sidebarOpen: Observable<boolean>;
    hamburgerChecked: boolean;

    constructor(private ui: UiService) {}

    reload(): void {
        window.location.replace(window.location.origin + window.location.pathname);
    }

    ngOnInit(): void {
        this.sidebarOpen = this.ui.sidebarOpen$.pipe(
            tap(() => {
                timer(0, 0)
                    .pipe(take(200))
                    .subscribe(() => {
                        window.dispatchEvent(new Event("resize"));
                    });
            })
        );
    }
}
