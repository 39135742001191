import { Component, EventEmitter, Output } from "@angular/core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-about-info",
    templateUrl: "./about-info.component.html",
    styleUrls: ["./about-info.component.scss"],
})
export class AboutInfoComponent {
    faExternalLink = faExternalLinkAlt;

    @Output()
    close: EventEmitter<void> = new EventEmitter();

    onClose(): void {
        this.close.emit();
    }
}
