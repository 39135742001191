export const shared = {
    mapName: "ggc-ol-map",
    printConfiguration: "print/woz",
    printLayout: "1. A4 landscape",
    printLayoutAlt: "2. A4 portrait",
    printMapScale: 800,
    highlightMaxZoom: 13.2,
    filterMaxZoom: 13.7,
    aotSuggestLimit: 10,
    pdokOrLimit: 30,
    vboFilterLimit: 100,
    kozSearchLimit: 25,
    resultWaardenLimit: 3,
    noLocationTimeout: 500,
    noLocationRepeat: 20,

    kadastraleLayer: {
        fill: "#CCCCCCBB",
        stroke: "#01689b",
        zIndex: 1001,
    },
    bagLayer: {
        fill: "#ddce86",
        stroke: "#01689b",
        zIndex: 1002,
    },
    filterLayer: {
        fill: "#01689b33",
        stroke: "#01689b",
        zIndex: 1000,
    },
};
