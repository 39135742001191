import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "app-voorscherm",
    templateUrl: "./voorscherm.component.html",
    styleUrls: ["./voorscherm.component.scss"],
})
export class VoorschermComponent {
    @Output()
    close: EventEmitter<void> = new EventEmitter();

    @Output()
    terms: EventEmitter<void> = new EventEmitter();

    onClose(): void {
        this.close.emit();
    }

    onTerms(): void {
        this.terms.emit();
    }
}
