import { Component, Input } from "@angular/core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-nav",
    templateUrl: "./nav.component.html",
    styleUrls: ["./nav.component.scss"],
})
export class NavComponent {
    @Input()
    hamburger: boolean;
    faExternalLink = faExternalLinkAlt;
}
