<nav aria-label="Navigeer door resultaten" *ngIf="suggestions?.length > 1">
    <ul class="pagination">
        <li class="page-item">
            <a
                class="page-navigation page-navigation-previous"
                href="#"
                aria-label="Vorig resultaat"
                title="Vorig resultaat"
                (click)="previousSuggestion()"
            >
                <span class="far fa-angle-left icon-prefix" aria-hidden="true"></span>Vorige
            </a>
        </li>
        <li class="page-item active">
            <span class="sr-only">huidige paginanummer</span>
            <span class="page-link">{{ indexSuggestion }} </span>
        </li>
        <li class="pagination-separator">van</li>
        <li class="page-item">
            <span class="page-link">{{ suggestions?.length }} <span class="sr-only">totaal aantal pagina's</span></span>
        </li>
        <li class="page-item">
            <a
                class="page-navigation page-navigation-next"
                href="#"
                aria-label="Volgend resultaat"
                title="Volgend resultaat"
                (click)="nextSuggestion()"
            >
                Volgende<span class="far fa-angle-right icon-suffix" aria-hidden="true"></span>
            </a>
        </li>
    </ul>
</nav>
