import { NgModule } from "@angular/core";
import { FilterModule } from "../filter/filter.module";
import { SharedModule } from "../shared/shared.module";
import { ResultaatComponent } from "./result/resultaat.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ResultaatSuggestiesComponent } from "./resultaat-suggesties/resultaat-suggesties.component";
import { PrintComponent } from "./print/print.component";
import { SidebarBlockComponent } from "./sidebar-block/sidebar-block.component";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ResultaatNavComponent } from "./result-nav/resultaat-nav.component";
import { SidebarLoaderComponent } from "./sidebar-loader/sidebar-loader.component";

@NgModule({
    declarations: [
        SidebarComponent,
        ResultaatComponent,
        ResultaatNavComponent,
        ResultaatSuggestiesComponent,
        PrintComponent,
        SidebarBlockComponent,
        SidebarLoaderComponent,
    ],
    imports: [SharedModule, FilterModule, RouterModule, FontAwesomeModule],
    exports: [SidebarComponent],
})
export class SidebarModule {}
