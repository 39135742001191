import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TermsService } from "../../service/terms.service";
import { Observable } from "rxjs";

@Component({
    selector: "app-info-modal",
    templateUrl: "./info-modal.component.html",
    styleUrls: ["./info-modal.component.scss"],
})
export class InfoModalComponent implements OnInit {
    modalState = {
        werking: false,
        gebruiksvoorwaarden: false,
        totstandkoming: false,
        veelgesteldevragen: false,
        werkingwozwaardeloket: false,
        uitleggegevens: false,
        toegangkelijkheidsverklaring: false,
    };

    get $termsAccepted(): Observable<boolean> {
        return this.termsService.$termsAccepted;
    }

    constructor(private route: ActivatedRoute, private router: Router, private termsService: TermsService) {}

    ngOnInit(): void {
        this.route.url.subscribe((url) => this.openByRef(url[0].path));
    }

    onClose(): void {
        Object.keys(this.modalState).forEach((key) => (this.modalState[key] = false));
        this.router.navigateByUrl("");
    }

    openByRef(ref: string): void {
        Object.keys(this.modalState).forEach((key) => (this.modalState[key] = key === ref));
    }
}
