import { Component, EventEmitter, Input, Output } from "@angular/core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-sidebar-block",
    templateUrl: "./sidebar-block.component.html",
    styleUrls: ["./sidebar-block.component.scss"],
})
export class SidebarBlockComponent {
    isOpen = true;

    @Input()
    set open(value: boolean) {
        this.isOpen = value;
    }

    @Input() closeable = true;
    @Input() identifier: string;
    @Input() title: string;
    @Input() toggleTitle: string;

    @Output() close = new EventEmitter();
    @Output() toggle = new EventEmitter<boolean>();

    @Input() searching: boolean;

    loadingIcon = faSpinner;

    onToggle(): void {
        this.toggle.emit(!this.isOpen);
    }

    onClose(): void {
        this.close.emit();
    }

    get isExpanded(): boolean {
        return this.isOpen;
    }
}
