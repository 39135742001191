import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

interface Endpoints {
    [endpoint: string]: string;
}

@Injectable({
    providedIn: "root",
})
export class EndpointsService {
    private endpoints: Endpoints;
    constructor(private http: HttpClient) {}

    load(): Observable<Endpoints> {
        return this.http.get<Endpoints>("assets/endpoints.json").pipe(tap((res) => (this.endpoints = res)));
    }

    get(endpoint: string): string {
        return this.endpoints[endpoint];
    }

    get bagService(): string {
        return this.endpoints.bagService;
    }

    get kadastralekaartService(): string {
        return this.endpoints.kadastralekaartService;
    }

    get wozService(): string {
        return this.endpoints.wozService;
    }
}
