import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ModalComponent } from "./modal/modal.component";
import { PrefixPipe } from "./utils/prefix.pipe";
import { StreetAddressPipe } from "./utils/street-address.pipe";
import { ErrorComponent } from "./error/error.component";
import { A11yModule } from "@angular/cdk/a11y";
import { NumericInputDirective } from "./directives/numeric-input.directive";

@NgModule({
    declarations: [PrefixPipe, ModalComponent, StreetAddressPipe, ErrorComponent, NumericInputDirective],
    providers: [PrefixPipe, StreetAddressPipe],
    imports: [CommonModule, A11yModule],
    exports: [CommonModule, PrefixPipe, ModalComponent, StreetAddressPipe, ErrorComponent, NumericInputDirective],
})
export class SharedModule {}
